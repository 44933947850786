import React, { useState, useEffect } from 'react';

const PoolDeadlineTimer = ({ invitationDeadline, signDeadline, onDeadlineExpire}) => {
    const ogNow = new Date();
    const [invitationDeadlinePassed, setInvitationDeadlinePassed] = useState(ogNow >= new Date(invitationDeadline));
    const [signDeadlinePassed, setSignDeadlinePassed] = useState(ogNow >= new Date(signDeadline));

    
    const calculateTimeRemaining = (deadline) => {
        const now = new Date();
        const deadlineDate = new Date(deadline);
        const timeDiff = deadlineDate - now;

        if (timeDiff > 0) {
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
            const seconds = Math.floor((timeDiff / 1000) % 60);

            if (days >= 7) {
                // More than a week
                return `${days} ${days === 1 ? 'day' : 'days'}`;
            } else if (days > 0) {
                // More than a day but less than a week
                return `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
            } else {
                // Less than a day
                return [
                    hours.toString().padStart(2, '0'),
                    minutes.toString().padStart(2, '0'),
                    seconds.toString().padStart(2, '0')
                ].join(':');
            }
        } else {
            return "Deadline passed";
        }
    };



    const checkDeadlines = () => {
        const now = new Date();
        const newInvitationDeadlinePassed = now >= new Date(invitationDeadline);
        const newSignDeadlinePassed = now >= new Date(signDeadline);

        // Trigger the callback if a deadline has just passed
        if (newInvitationDeadlinePassed && !invitationDeadlinePassed) {
            setInvitationDeadlinePassed(true);
            onDeadlineExpire();
        }
        if (newSignDeadlinePassed && !signDeadlinePassed) {
            setSignDeadlinePassed(true);
            onDeadlineExpire();
        }
    };

    

    const getRemainingTimeText = () => {
        checkDeadlines();
        if (invitationDeadlinePassed && signDeadlinePassed) {
            return <>Active Pool</>;
        } else if (invitationDeadlinePassed) {
            return (
                <>
                    {calculateTimeRemaining(signDeadline)}
                </>
            );
        } else {
            return (
                <>
                    {calculateTimeRemaining(invitationDeadline)}
                </>
            );
        }
    };
    

    const [remainingTime, setRemainingTime] = useState(getRemainingTimeText());

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(getRemainingTimeText());
        }, 1000);

        return () => clearInterval(interval);
    }, [invitationDeadline, signDeadline]);

    return (
        <>
            {remainingTime}
        </>
    );
};



export default PoolDeadlineTimer;
