import React, { useRef, useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { StyledButton, StyledRangeInput, StyledModal } from './StyledBootstrap';
import StyledModalClose from './StyledModalClose';
import { useTheme } from 'styled-components';
import { useAuth } from './AuthContext';
import Loading from './Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { myAxiosInstance } from './axiosConfig';
import { useTracking } from 'react-tracking';
import { errorString } from './CriticalFunctions';
import { faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProfilePictureUpload.css';

const ProfilePictureUpload = ({ setMiddleReload, middleReload }) => {
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [isSendingLoading, setIsSendingLoading] = useState(false);
    const [bottomReload, setBottomReload] = useState(false);
    const { Track, trackEvent } = useTracking({ button: "Profile Picture" });

    const { setProfilePictureLink } = useAuth();


    const editorRef = useRef(null);
    const fileInputRef = useRef(null);

    const handleScale = (e) => {
        setScale(parseFloat(e.target.value));
    };

    const handlePositionChange = (position) => {
        setPosition(position);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setModalIsOpen(true);
        } else if (!modalIsOpen) {
            setModalIsOpen(false);
        }
    };

    const fetchImageUrl = async () => {
        try {
            const response = await myAxiosInstance.get('/api/update-profile-picture/');
            if (response.data.profile_picture == null) {
                setImageUrl("");
                setProfilePictureLink("");
            } else {
                setImageUrl(response.data.profile_picture);
                setProfilePictureLink(response.data.profile_picture);
                setScale(1);
                setPosition({ x: 0.5, y: 0.5 });
            }

            setIsBottomLoading(false);
        } catch (error) {
            trackEvent({ error: errorString(error) });
            console.error('Error fetching the image URL', error);
        }
    };

    const handleSave = async () => {
        if (!isSendingLoading) {
            setIsSendingLoading(true);


            // Convert canvas to blob
            editorRef.current.getImage().toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('profile_picture', blob, 'profile_pic.png');
                try {
                    await myAxiosInstance.put('/api/update-profile-picture/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setModalIsOpen(false);
                    setMiddleReload && setMiddleReload(!middleReload);
                    trackEvent({ action: "Save" });
                } catch (error) {
                    console.error('Error uploading the image', error);
                    trackEvent({ action: "Save", error: errorString(error) });
                } finally {
                    setIsSendingLoading(false);
                    setBottomReload(!bottomReload);

                }
            });
        }
    };


    const handleClose = () => {
        if (!isSendingLoading) {
            setImage(null);
            setModalIsOpen(false);
        }
    };

    useEffect(() => {
        fetchImageUrl();
    }, [bottomReload]);

    const handleProfileButtonClick = () => {
        trackEvent({});
        if (!isBottomLoading) {
            if (imageUrl !== "") {
                setModalIsOpen(true);
            } else {
                fileInputRef.current.click();
            }
        }
    };



    return (
        <Track>
            <div>
                Edit Profile Picture
                <div className="circle-button" onClick={handleProfileButtonClick}>
                    {isBottomLoading ? (
                        <Loading />
                    ) : (
                        <>
                            {imageUrl !== "" ? (
                                <img src={imageUrl} alt="Profile Avatar" />
                            ) : (
                                <span><FontAwesomeIcon icon={faUser} /></span>
                            )}
                            <div className='required-star'>
                                *
                            </div>
                        </>
                    )}
                </div>
                {/*<StyledButton onClick={handleProfileButtonClick}>
                    {isBottomLoading ? <Loading /> : imageUrl !== "" ? "Edit Profile Picture" : <>Upload Profile Picture<span className="text-danger"><strong>*</strong></span></>}
                </StyledButton>*/}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <StyledModalClose show={modalIsOpen} onHide={handleClose} title={"Edit Profile Picture"}>
                    <StyledModal.Body className='d-flex flex-column justify-content-center align-items-center'>
                        {(image || imageUrl) && (
                            <AvatarEditor
                                ref={editorRef}
                                image={image || imageUrl}
                                width={250}
                                height={250}
                                border={25}
                                borderRadius={125} // Circular crop
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={scale}
                                position={position}
                                onPositionChange={handlePositionChange}
                                rotate={0}
                                crossOrigin='anonymous'
                            />
                        )}
                        <br />
                        <div style={{ width: "50%" }}>
                            <StyledRangeInput
                                onChange={handleScale}
                                min="1"
                                max="2"
                                step="0.01"
                                value={scale}
                            />
                        </div>
                    </StyledModal.Body>
                    <StyledModal.Footer>
                        <StyledButton variant="secondary" onClick={() => !isSendingLoading && fileInputRef.current.click()}>
                            Upload Different Picture
                        </StyledButton>
                        <StyledButton variant="primary" onClick={handleSave}>
                            {isSendingLoading ? <Loading /> : "Save"}
                        </StyledButton>
                    </StyledModal.Footer>
                </StyledModalClose>

            </div>
        </Track>
    );
};

export default ProfilePictureUpload;