import axios from 'axios';
import { useAuth } from './AuthContext';
import Loading from './Loading';
import { myAxiosInstance } from './axiosConfig';


export async function apiP(data, endpoint, setMessage, successMessage, navigate, navigateUrl, r, rv, setR, requestType, trackEvent) {
    setMessage(<Loading />);
    console.log(data);
    let response = null;
    try {
        if (requestType == 'put') {
            if (data) {
                response = await myAxiosInstance.put('/api/' + endpoint, data);
            }
            else {
                response = await myAxiosInstance.put('/api/' + endpoint);
            }

        }
        else if (requestType == 'post') {
            if (data) {
                response = await myAxiosInstance.post('/api/' + endpoint, data);
            }
            else {
                response = await myAxiosInstance.post('/api/' + endpoint);
            }
        }
        else if (requestType == 'delete') {
            if (data) {
                response = await myAxiosInstance.delete('/api/' + endpoint, data);
            }
            else {
                response = await myAxiosInstance.delete('/api/' + endpoint);
            }
        }

        if (r) {
            setR(rv => rv + 1);
        }
        setMessage(successMessage);
        if (navigate) {
            navigate(navigateUrl);
        }

        return response;
    }
    /*catch (error) {
        console.log(error);
        console.log(error.response.data);
        //setMessage(Object.values(error.response.data));
        setMessage(ErrorList(error.response.data));
        return null;
    }*/
    catch (error) {
        console.log(error);
        if (trackEvent)
            {
                trackEvent({error: errorString(error)});
            }
        
        if (error.response && error.response.data) {
            // Construct the error list JSX and pass it to setMessage
            const errorListJSX = (
                <div>
                    <ul>
                        {Object.entries(error.response.data).map(([key, value]) => (
                            <li key={key}>
                                <strong>{key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}:</strong> {value[0]}
                            </li>
                        ))}
                    </ul>
                </div>
            );
            setMessage(errorListJSX);
        } else {
            // Handle other types of errors or set a generic message
            setMessage(<div>An unexpected error occurred.</div>);
        }
    }
    
}





export const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    const saturation = 80;
    const lightness = 70;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};


export function formatToDollar(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,  // No decimal places
        maximumFractionDigits: 0   // No decimal places
    }).format(amount);
}


export function formatGraduationDate(gradDate) {
    const date = new Date(gradDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScript months are 0-indexed

    let formattedYear;
    if (month <= 6) {
        // First 6 months
        formattedYear = `${year.toString().slice(2)}`;
    } else {
        // Next 6 months
        formattedYear = `${year.toString().slice(2)}.5`;
    }

    return formattedYear;
}

export function formatGraduationDateSummer(gradDate) {
    const date = new Date(gradDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScript months are 0-indexed

    let formattedYear;
    if (month <= 6) {
        // First 6 months
        formattedYear = `Spring '${year.toString().slice(2)}`;
    } else {
        // Next 6 months
        formattedYear = `Fall '${year.toString().slice(2)}`;
    }

    return formattedYear;
}


export const convertUTCtoLocalDateTime = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};


export function timeSince(dateString) {
    const date = new Date(dateString);
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + "m";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + "m";
    }
    return Math.floor(seconds) + "s";
}



export const pastDeadline = (deadline) => {
    const now = new Date();
    const deadlineDate = new Date(deadline);
    const timeDiff = deadlineDate - now;

    if (timeDiff < 0) {

        return true;

    }
    return false;
}




export function stylePoolSlug(poolSlug)
{
    
    return (
        <>
        <span >{poolSlug[0]}</span>
        <span>{poolSlug.slice(1)}</span>
        </>

    );
}



export function convertPackageToGreek(poolPackage)
{
    if (poolPackage==="Alpha")
    {
        return (<span>&alpha;</span>);
    }
    else if (poolPackage==='Gamma')
    {
        return (<span>&gamma;</span>);
    }
    else if (poolPackage==="Delta")
    {
        return (<span>&Delta;</span>);
    }
    return (<span>&Omega;</span>);
}



export function errorString(error) {
    if (error.response && error.response.data) {
        // Construct the error list string
        return Object.entries(error.response.data).map(([key, value]) => {
            return `${key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}: ${value[0]}`;
        }).join(', ');
    } else {
        // Handle other types of errors or set a generic message
        return 'An unexpected error occurred.';
    }
}

