import { StyledH2, StyledH1, StyledH3, StyledH4, StyledParagraph, StyledCard, StyledAccordion } from "./StyledBootstrap";
import { useTheme } from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons';


function FAQs() {
    const themeMode = useTheme();
    return (
        <Row className='justify-content-center'>
            <Col className='col-md-9'>
                <StyledCard className='mb-3'>
                    <StyledCard.Header className='text-center'>
                        <StyledH2 style={{ color: "white" }}>FAQs</StyledH2>
                    </StyledCard.Header>
                    <StyledCard.Body>
                        <StyledAccordion>
                            <StyledAccordion.Item eventKey="0">
                                <StyledAccordion.Header>
                                    <StyledH4>How Does a Success Pool Work? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Every year, members contribute a certain percentage (the “Rate”) of their income over a threshold (the “Threshold”) to a shared pool.
                                        For example, if you make $1.1m with a $1m threshold and a 2% rate, you would contribute $2000 into the pool. The shared pool is then split evenly among everyone in the group. This agreement lasts for a set number of years (the “Horizon”).
                                        This process occurs in the month of November, and applies to income made in the previous calendar year (but will only begin next calendar year).
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="1">
                                <StyledAccordion.Header>
                                    <StyledH4>Which Are The Mechanisms of Pool Formation/Creation? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        <strong>Creating or Joining Your Pool:</strong> Click “Create Pool”, choose a Success Pool package, and send invitations to the members you want. You are considered the "pool captain" of pools you create. Alternatively, you can accept an invite to an existing pool created by another captain.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Invitation Period:</strong> Before the invitation deadline, the pool is flexible. Members can join and leave, and the captain can change the terms of the pool. After the invitation deadline, the pool is locked. The terms are set, and no one else can join.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Signing Period:</strong> After the invitation deadline expires, the pool enters the Signing Period. Members can choose to sign the final success pooling agreement with the set terms and members.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Activation:</strong> If a certain number of members sign the agreement by the signing deadline, the pool activates and the agreement goes into effect between them. Members who did not sign are not part of the pool.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Tryout:</strong> Even after signing, you have a month (or sometimes longer) to "try out" the pool. If you change your mind, you can cancel your signature. This ensures you are comfortable and have enough time to make your decision.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>








                            <StyledAccordion.Item eventKey="2">
                                <StyledAccordion.Header>
                                    <StyledH4>Why These Terms?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Success pools have different functions depending on their terms. For example, the primary thesis behind a pool with a $1,000,000 threshold, 2% rate, and a 10-year horizon is to gather a fantastic network of smart and driven people and give each person a stake in each other's significant upside success. The primary benefit will be the insights, networks, opportunities, and resources that this group will share with each other for the horizon of the pool. On the other hand, the primary goal of a pool with a $0 threshold and 10% rate for 5 years might be pure career diversification for a group of quantitative traders.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>



                            <StyledAccordion.Item eventKey="3">
                                <StyledAccordion.Header>
                                    <StyledH4>Why Should I Pool with Someone Younger/Older Than Me?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Your contribution term is tied to your graduation date. This ensures that contributions are fair and tied to earning terms in the professional world. While they are still in school, a junior may receive distributions from a senior who just joined the workforce (and is not earning anything). But they will continue to contribute one year later than the senior, as the senior’s contribution term expires. We also sincerely hope that connections across years will benefit pools, as different members contribute different ideas and perspectives.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="4">
                                <StyledAccordion.Header>
                                    <StyledH4>When Will I Get to Meet Everyone?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        You can view each member's bio on the platform itself. After the signing period ends, every member will be invited to a Slack workspace to introduce themselves and start interacting. Additionally, during the tryout period of the pool, we will host virtual meet-and-greets so that members can get to know each other better.
                                        But all of this is just the beginning. We have many more ideas for retreats, events, and ways for pool members to interact. Over the coming months, we will also embed new functionalities and features into the platform itself.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="5">
                                <StyledAccordion.Header>
                                    <StyledH4>Why Should I Join if I Think I Will Make More Money Than Everyone Else?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Many of the other people in this group are also thinking the same thing.
                                        They are joining the pool because they understand that life is full of uncertainty, and outcomes are tied to factors other than perceived “talent”. “High earning” jobs now may not be high earning in the future, and vice versa. An array of events beyond your control will likely play a large role in your success, from economic shifts to personal challenges to technological advances. Success pooling provides something you can’t achieve on your own - a way to invest in multiple life paths, both in terms of financial and social capital, hedging against the downside and capturing the upside of all of these unpredictable factors.
                                        In economic terms, we believe that the expected utility for each individual member, at the moment of joining, is positive. The total expected dollar value of the pool is neutral (or if members help each other because of aligned financial incentives, it can also be positive).
                                        Treat this like an early-stage venture fund that only you have access to. You should be betting on the people themselves, rather than any sort of precise salary progression calculation based on your predictions of their current career trajectory. Remember, Peter Thiel was a philosophy major who clerked in a federal court before he founded PayPal and Palantir.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="6">
                                <StyledAccordion.Header>
                                    <StyledH4>What Happens If Prospinity Shuts Down?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        There are several options we can pursue, after gathering input from pool members. We have the option to assign rights to another Pool Manager, who can service the pool. Or we can simply leave the agreement be (contributions and distributions would still be legally binding but would have no backend to enforce/administrate them).
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="7">
                                <StyledAccordion.Header>
                                    <StyledH4>How are Success Pools taxed?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        We ensure through our platform that contributions and distributions are net. We will pre-calculate your owed contribution and owed distribution, and only transfer income once (instead of paying you back). Thus, there is never a situation where you put money into the pool and then get it back in the same year.
                                        If you are a net “gainer” from the pool, money from the pool is taxed like any other income you receive (most conservatively as ordinary income). If you are a net “contributor” to the pool, you write off a minuscule amount of your earnings relative to your overall income.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="8">
                                <StyledAccordion.Header>
                                    <StyledH4>What Counts as Income?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        It depends on the goal of the pool. For some pools, we may define income as “total income” (line 9 of the Form 1040 for the 2023 Tax Year or substantial equivalents). This includes wages, royalties, bonus, carried interest, interest, dividends, and realized capital gains. It excludes inheritance and illiqiud gains, and we carve out spousal income.
                                        This is a definition of income that errs on the side of transparency and comprehensibility, so people feel comfortable knowing what is and is not counted. If the goal of the pool is primarily to create a community of people with aligned financial and social incentives, this definition works well.
                                        For other pools, we use versions of the Agreement that capture other forms of income, such as illiquid gains. These versions are for pools that would benefit from optimized income transfer and diversification.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="9">
                                <StyledAccordion.Header>
                                    <StyledH4>Who Will Enforce This? How Does the Legal Side Work?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        While we expect that the community and trust we build long-term will prevent people from violating the terms, we are prepared for scenarios where someone refuses to pay.
                                        We have developed an enforceable legal agreement in partnership with a law firm and specialists in their FinTech practice that every member agrees to. It is our role as a company to verify income and enforce these agreements (and why it is impossible to do this independently with your friends).
                                        We have a series of dispute mechanisms in the agreement itself. We carefully balanced two things: fairness to the person who is disputing a payment, but also strength, to make sure the integrity of the agreement is not breached by a malicious actor. This is our business, and we have a vested interest in making the pool work.
                                        We verify income through a combination of tax returns for the relevant jurisdiction and other supporting documentation. Confidentiality and privacy are our bedrock values as a company. Contributions and distributions will always stay anonymous.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="10">
                                <StyledAccordion.Header>
                                    <StyledH4>How Does Prospinity Make Money?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        There are no upfront payments. We take a 2% commission on the pool (this means 2% of the 2% over $1m). A small stake in the game aligns our incentives with yours, ensuring that the success pooling journey runs smoothly and seamlessly. This commission is embedded into the Agreement and will stay constant throughout the duration of the pool (we cannot change it).
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="11">
                                <StyledAccordion.Header>
                                    <StyledH4>What is the Minimum Threshold?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        If a member’s income dips below the minimum threshold (e.g. $50k) in a given year, they are excluded from distributions for that year. This ensures that if someone “hits it big”, other members of the pool are still incentivized to produce and contribute, and not "freeride".
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="12">
                                <StyledAccordion.Header>
                                    <StyledH4>How Does the Cancellation Period Work?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        If for some reason, you would like to withdraw from the pool, you have 30 days to exercise a cancellation right after the pool becomes active. This feature is to make people more comfortable signing and give them more time to fully make the decision. To exercise the right, send an email to support@prospinity.com with the subject line “Pool Cancellation”, with your name and the name of this pool in the body of the email is sufficient to withdraw completely.
                                        Of course, during the Cancellation Period, if the number of members in the pool drops below the Minimum Size due to cancellations, the Pool does not activate. This way, we ensure that you always signing with the Minimum Size of listed members.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="13">
                                <StyledAccordion.Header>
                                    <StyledH4>What Happens if the Pool Does Not Reach the Minimum Size, before or during the Cancellation Period?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        All the normal “social” functions of the pool that we overlay on top of the Agreement will still be in place (Slack channel, events), but no contractual, financial obligations are active unless the Pool reaches the Minimum Size. The pool is in “Search Mode”, and has a six-month window to add new members to reach the Minimum Size.
                                        Members of the Pool may suggest students they think may be a good fit, or alternatively, we may suggest students on our platform. There is a simple voting system in place to “add” members: if at least 75% of the members agree to include the person, they are added to the Pool.
                                        As soon as the Pool reaches the Minimum Size, everything mentioned above still applies! The “Cancellation Period” kicks in, and you still have the opportunity to cancel your signature for 30 days. This way, the ultimate decision to join a pool is fully up to you.
                                        If the Pool is not above the Minimum Size in six months, the Agreement is completely terminated.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>


                            <StyledAccordion.Item eventKey="14">
                                <StyledAccordion.Header>
                                    <StyledH4>How do 1:1 Meetings Work? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        After you have completed your profile, click on the Meetings tab and add preferred meeting times. Our AI matches you weekly with peers based on your background and goals.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="15">
                                <StyledAccordion.Header>
                                    <StyledH4>How Do 1:1 Meetings Help With Success Pooling? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        These meetings allow you to understand each other's goals and strengths, creating a foundation for forming successful, collaborative pools. The networking aspect helps you expand your professional connections, potentially identifying future pool mates or simply broadening your support network.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>


                            <StyledAccordion.Item eventKey="16">
                                <StyledAccordion.Header>
                                    <StyledH4>Other Questions?  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        For any other questions or clarifications, please email <a href={"mailto:support@prospinity.com"}>support@prospinity.com</a>.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                        </StyledAccordion>
                    </StyledCard.Body>


                </StyledCard>
            </Col>
        </Row>

    );
}


export default FAQs;