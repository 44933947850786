import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { StyledButton, StyledCard, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledForm, StyledParagraph } from './StyledBootstrap';
import logo from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import axios from 'axios';
import EmailVerificationSent from './EmailVerificationSent';

function PasswordReset() {
    const themeMode = useTheme();
    const { verificationKey, slug } = useParams();
    const [message, setMessage] = useState("Enter a new password.");
    const [changed, setChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');

    async function sendPasswordVerificationKey(e) {
        e.preventDefault();
        try {
            setIsLoading(true);
            console.log({ new_password1: password, new_password2: verifyPassword, slug: slug, token: verificationKey });
            const response = await axios.post('/dj-rest-auth/password/reset/confirm/', { new_password1: password, new_password2: verifyPassword, slug: slug, token: verificationKey });
            console.log(response);
            setMessage("Password Changed. Please login again with new your new password.");
            setChanged(true);
        } catch (error) {
            console.log(error);
            setMessage(Object.values(error.response.data)[0][0]);
        }
        finally{
            setIsLoading(false);
        }
    }


    return (
    changed ? <EmailVerificationSent><StyledParagraph className='mt-3'>Password changed. Please <Link to="/signin">sign in</Link> with your new password.</StyledParagraph></EmailVerificationSent> :
        <div>
            <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>

                    <StyledCard>
                        <StyledCard.Body>
                            <Row className='justify-content-center mb-3'>
                                <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>
                                </Col>
                            </Row>
                    <StyledForm onSubmit={(e) => sendPasswordVerificationKey(e)}>
                        <StyledFormGroup controlId="formBasicPassword">
                            <StyledFormLabel>Enter New Password</StyledFormLabel>
                            <StyledFormControl
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </StyledFormGroup>
                        <StyledFormGroup controlId="formVerifyPassword">
                            <StyledFormLabel>Re-Enter New Password</StyledFormLabel>
                            <StyledFormControl
                                type="password"
                                placeholder="Verify Password"
                                value={verifyPassword}
                                onChange={(e) => setVerifyPassword(e.target.value)}
                            />
                        </StyledFormGroup>
                        <StyledButton variant="primary" type="submit" className="mt-3 w-100">
                            Sign Up
                        </StyledButton>
                    </StyledForm>
                    <div className='text-center'>
                        {isLoading && <StyledParagraph>Changing Password...</StyledParagraph>}
                        {message && <StyledParagraph>{message}</StyledParagraph>}
                    </div>
                    </StyledCard.Body>
                    </StyledCard>
                    </Col>
                </Row>
            </Container>

        </div>);
}


export default PasswordReset;
