import { Row, Col } from "react-bootstrap";
import { StyledButton, StyledCard, StyledH5, StyledParagraph } from "./StyledBootstrap";
import Availability from "./Availability";
import { useState } from "react";
import CustomModal from "./CustomModal";
import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { myAxiosInstance } from "./axiosConfig";
import Loading from "./Loading";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import EditMeetingGoalsModal from "./EditMeetingGoalsModal";

const Meet = () => {

    const [weeklyAvailability, setWeeklyAvailability] = useState(null);
    const [meetingGoal, setMeetingGoal] = useState(null);
    const [editWeeklyAvailability, setEditWeeklyAvailability] = useState(null);
    const [updateAvailabilityModalShow, setUpdateAvailabilityModalShow] = useState(false);
    const [updateGoalsModalShow, setUpdateGoalsModalShow] = useState(false);

    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [updateMessage, setUpdateMessage] = useState(<>Set your weekly default availability for 1:1 AI matched meetings with other Prospinity members.</>);
    const [updateLoad, setIsUpdateLoad] = useState(false);
    const [bottomReload, setBottomReload] = useState(1);

    const { userSlug } = useAuth();

    const { showBoundary } = useErrorBoundary();
    const daysOfWeek = { 'Monday': 'Mo', 'Tuesday': 'Tu', 'Wednesday': 'We', 'Thursday': 'Th', 'Friday': 'Fr', 'Saturday': 'Sa', 'Sunday': 'Su' };
    const [timeOptions, setTimeOptions] = useState({ 'Monday': [11, 20], 'Tuesday': [], 'Wednesday': [14, 22], 'Thursday': [], 'Friday': [11, 16], 'Saturday': [1], 'Sunday': [12, 16, 19] });
    const daysOfWeekOrdered = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const areAllTimesUnavailable = (day) => {
        return !timeOptions[day].some(time => weeklyAvailability[day].includes(time));
    };
    
    
    
    const convertToTime = (time) => {

        if (time > 12) {
            return ((time - 12) + 'pm');
        }
        else {
            return (time + 'am');
        }




    };
    const updateAvailability = async () => {
        setIsUpdateLoad(true);
        console.log(JSON.stringify(editWeeklyAvailability));
        setUpdateMessage(<Loading />);
        try {
        const response = await myAxiosInstance.put('/api/user-availability/', {"availability": JSON.stringify(editWeeklyAvailability)});
        }
        catch (error) {
            console.error('Error updating user availability', error.response.data);
            showBoundary(error);
        }
        finally
        {
            setIsUpdateLoad(false);
            setBottomReload(!bottomReload);
        }
        
        console.log("Update");
    };

    const handleUpdateButton = () => {
        setUpdateAvailabilityModalShow(true);
    }


    const handleUpdateGoalsButton = () => {
        setUpdateGoalsModalShow(true);
    }

    const handleUpdateModalClose = () => {
        if (!updateLoad)
        {
            setUpdateAvailabilityModalShow(false);
        }
        
    }

    useEffect(
        () => {
            const getAvailability = async () => {
            try {
                setBottomReload(false);
                setUpdateMessage(<>Set your weekly default availability for 1:1 AI matched meetings with other Prospinity members.</>);
                setUpdateAvailabilityModalShow(false);
                const response = await myAxiosInstance.get('/api/user-availability/');

                let av = response.data.availability;

                if (typeof av === "string") {
                    av = JSON.parse(av);
                }


                
                if (Object.keys(av).length == 0)
                {
                    setWeeklyAvailability({ 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [], 'Sunday': [] });
                    setEditWeeklyAvailability({ 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [], 'Sunday': [] });
                }
                else{
                    setWeeklyAvailability(av);
                    setEditWeeklyAvailability(av);
                }

                setMeetingGoal(response.data.goal);
            }
            catch (error) {
                console.error('Error fetching user availability', error.response.data);
                showBoundary(error);
            }
            finally {
                setIsBottomLoading(false);
            }

            }


            getAvailability();
        }, [bottomReload]

    );

    return (
        <>
            <Row className='justify-content-center'>
                <Col className='col-lg-4 col-md-6'>
                    <StyledCard>
                        <StyledCard.Body>
                            <StyledH5 className="text-center">Meetings</StyledH5>
                            <StyledParagraph>No meetings scheduled. If you entered your availability, wait for an invite and ensure your <Link to={"/dashboard/user/" + userSlug}>bio</Link> is up to date.</StyledParagraph>
                        </StyledCard.Body>
                    </StyledCard>
                    <StyledCard className='mt-3'>
                        <StyledCard.Body className="d-flex flex-column justify-content-center align-items-center">
                            <StyledH5>Availability</StyledH5>
                            {
                                weeklyAvailability ? 
                            
                            
                            
                            daysOfWeekOrdered.map((day) => (!areAllTimesUnavailable(day) ? <div style={{ width: '40%' }} className='d-flex justify-content-between'><div>{daysOfWeek[day]}</div> <div>{timeOptions[day].map((time) => (<>{weeklyAvailability[day].includes(time) ? convertToTime(time) : <></>} </>))}</div></div> : <></>))

                            
                            
                            : <Loading />}
                            <StyledButton onClick={handleUpdateButton} className='mt-2'>Update</StyledButton>
                        </StyledCard.Body>
                    </StyledCard>


                    <StyledCard className='mt-3'>
                        <StyledCard.Body className="d-flex flex-column justify-content-center align-items-center">
                            <StyledH5>Goal</StyledH5>
                            <StyledParagraph>{meetingGoal ? meetingGoal : "None"}</StyledParagraph>
                            <StyledButton onClick={handleUpdateGoalsButton} className='mt-2'>Update</StyledButton>
                        </StyledCard.Body>
                    </StyledCard>

                </Col>
            </Row>

            <CustomModal title={'Update Availability'} actionText={'Save'} actionFunction={updateAvailability} show={updateAvailabilityModalShow} message={updateMessage} handleClose={handleUpdateModalClose}>
                <Availability weeklyAvailability={editWeeklyAvailability} setWeeklyAvailability={setEditWeeklyAvailability} edit={true} isBottomLoading={isBottomLoading}/>
            </CustomModal>
            <EditMeetingGoalsModal onSave={() => {setBottomReload(!bottomReload)}} updateGoalModalShow={updateGoalsModalShow} setUpdateGoalModalShow={setUpdateGoalsModalShow}/>
        </>







    );






}

export default Meet;


/*                            Object.keys(weeklyAvailability).map((day) => (weeklyAvailability[day].length > 0 ? <div style={{ width: '40%' }} className='d-flex justify-content-between'><div>{daysOfWeek[day]}</div> <div>{weeklyAvailability[day].map((time) => (<>{convertToTime(time)} </>))}</div></div> : <></>))
*/