import { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown, FormGroup, Card } from 'react-bootstrap';
import logo from './Images/Logo1.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiP } from './CriticalFunctions';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import { FormInput } from './FormComponents';
import { useAuth } from './AuthContext';
import { useTheme } from 'styled-components';
import { StyledForm, StyledModal, StyledRangeInput, StyledFormControl, StyledCard, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph, StyledPhoneInput, StyledTextArea, StyledFormCheck } from './StyledBootstrap';
import FormTooltip from './FormTooltip';
import logo_white from './Images/logo_white.png';
import logo_navy from './Images/logo_navy.png';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Availability from './Availability';
import ProfilePictureUpload from './ProfilePictureUpload';
import {useTracking} from 'react-tracking';

function FinishRegistration() {
    const { handleLogout, reload, setReload } = useAuth();
    const themeMode = useTheme();
    const [message, setMessage] = useState('');

    const { trackEvent, Track } = useTracking(
        {event: "Finish Registration"},
        
      );



    const [introModalStep, setIntroModalStep] = useState(1);

    const handleLeft = () => {
        if (introModalStep == 1) {
            handleClose();
        }
        else {
            setIntroModalStep(1);
        }

    }
    const handleRight = () => {
        if (introModalStep == 1) {
            setIntroModalStep(2);
        }
        else {
            handleClose();
        }

    }



    const [finishModalShow, setFinishModalShow] = useState(true);

    const handleClose = () => {
        setFinishModalShow(false);
    };

    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        university: [],
        major_or_grad_program: [],
        company: [],
        graduation_date: '',
        personal_email: '',
        phone: '',
        bio: '',
        preferred_horizon: '0',
        preferred_rate: '0',
        preferred_threshold: '0',
        prefer_prospinity: true,
        availability: {},
        other_preference: '',
        linkedin_url: '',
        goal: ''
    });

    const [weeklyAvailability, setWeeklyAvailability] = useState({ 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [], 'Sunday': [] });

    const handlePhoneChange = (value) => {
        // Custom handler for phone number input

        setUserInfo(prevData => ({
            ...prevData,
            phone: value ? value : "",
        }));
    };

    const handleRadioChange = (value) => {
        setUserInfo(prevData => ({
            ...prevData,
            prefer_prospinity: value === "Yes"
        }));
    };



    const handleGoalRadioChange = (value) => {
        setUserInfo(prevData => ({
            ...prevData,
            goal: value
        }));
    };


    const goals = [ "Meet peers in similar careers.", "Learn about different career paths.", "Share and receive feedback on ideas.", "Start a company." ];


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const handleFieldChange = ({ field, val }) => {
        setUserInfo({ ...userInfo, [field]: val });
    };

    const availabilityEmpty = (availability) => {
        return Object.values(availability).every(arr => arr.length === 0);
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        if (step < 4) {
            trackEvent({button: "Next", step: step});
            setStep(step + 1);
        } else {
            let data = {
                ...userInfo,
                university: userInfo.university.length === 0 ? '' : userInfo.university[0],
                company: userInfo.company.length === 0 ? '' : userInfo.company[0]
            };
            if (!availabilityEmpty(weeklyAvailability)) {
                console.log("not empty");
                data = {
                    ...data,
                    availability: weeklyAvailability

                }
            }
            console.log("data", data);
            trackEvent({button: "Submit"});
            apiP(data, "finish-registration/", setMessage, "Registration finished! Taking you to your dashboard", navigate, "/dashboard", true, reload, setReload, "put", trackEvent);
        }
    };

    const handlePrevious = () => {
        if (step > 1) {
            trackEvent({button: "Back", step: step});
            setStep(step - 1);
        } else {
            trackEvent({button: "Logout", step: step});
            handleLogout();
        }
    };


    /*useEffect(() => {
        if (message !== '' && message != null)
            {
                trackEvent({error: message});
            }
    }, [message]);*/




    return (
        <Track>
            <Container fluid style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', backgroundColor: themeMode.background }}>
                <Row className='w-100 justify-content-center '>
                    <Col className='col-md-6 d-flex flex-column justify-content-center'>
                        <StyledCard className='my-3'>
                            <StyledCard.Body>
                                <Row className='justify-content-center mb-3'>
                                    <Col md={10} className="text-center">
                                        <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
                                    </Col>
                                </Row>


                                <StyledForm onSubmit={(e) => handleSubmit(e)}>
                                    {step === 1 && (
                                        <>
                                            <StyledParagraph className='text-center mb-0' style={{ fontSize: '1.25rem' }}><strong>User Information</strong></StyledParagraph>
                                            <FormInput label="First Name" dataArr={userInfo} handleFieldChange={handleFieldChange} fieldName='first_name' select={false} options={null} extra={<><span className="text-danger">*</span></>}/>
                                            <FormInput label="Last Name" dataArr={userInfo} handleFieldChange={handleFieldChange} fieldName='last_name' select={false} options={null} extra={<><span className="text-danger">*</span></>}/>
                                            <FormInput label="Personal Email" dataArr={userInfo} handleFieldChange={handleFieldChange} fieldName='personal_email' select={false} options={null} extra={<><span className="text-danger">*</span><FormTooltip message="We require this so we can transition your account to this email once your school email expires." /></>} />
                                            <SingleSelect
                                                endpoint='search-universities'
                                                value={userInfo.university}
                                                onChange={(selected) => {
                                                    setUserInfo({ ...userInfo, university: selected });
                                                }}
                                                label="University"
                                                extra={<><span className="text-danger">*</span></>}
                                            />
                                            
                                            <StyledFormGroup>
                                                <StyledFormLabel>Phone Number<span className="text-danger">*</span><FormTooltip message="We send text reminders when deadlines for joining a pool are coming up so you don't miss anything." /></StyledFormLabel>
                                                <StyledPhoneInput
                                                    placeholder="Enter phone number"
                                                    value={userInfo.phone}
                                                    onChange={handlePhoneChange}
                                                    defaultCountry="US"
                                                    name="phone"
                                                    className="custom-phone-input"
                                                />
                                            </StyledFormGroup>
                                            <StyledFormGroup>
                                                <StyledFormLabel>Graduation Date<span className="text-danger">*</span><FormTooltip message="If you don't have an exact date, feel free to estimate." /></StyledFormLabel>
                                                <StyledFormControl
                                                    type="date"
                                                    value={userInfo['graduation_date']}
                                                    onChange={e => handleFieldChange({ field: 'graduation_date', val: e.target.value })}
                                                />
                                            </StyledFormGroup>
                                            <MultiSelect
                                                endpoint='search-degrees'
                                                value={userInfo.major_or_grad_program}
                                                onChange={(selected) => {
                                                    setUserInfo({ ...userInfo, major_or_grad_program: selected });
                                                }}
                                                label="Program(s) of Study"
                                                extra={<><span className="text-danger">*</span><FormTooltip message="This is your major, degree program, etc. If you can't find yours, please choose the closest one and you can change it later - we are constantly updating our database." /></>}
                                            />
                                            <SingleSelect
                                                endpoint='search-companies'
                                                value={userInfo.company}
                                                onChange={(selected) => {
                                                    setUserInfo({ ...userInfo, company: selected });
                                                }}
                                                label="Highlighted Work Experience"
                                                extra={<FormTooltip message="The internship/career experience that you have had (or are about to have) you would like to highlight most. If you can't find yours, please choose the closest one and you can change it later - we are constantly updating our database." />}

                                            />
                                            
                                        </>
                                    )}
                                    {step === 2 && (
                                        <>
                                            <StyledParagraph className='text-center' style={{ fontSize: '1.25rem' }}><strong>Meeting Preferences</strong></StyledParagraph>
                                            {/*<StyledParagraph className='text-center mt-0' style={{ fontSize: '0.75rem' }}>(Not limiting, just a tool for us to get a better idea of your preferences.)</StyledParagraph>*/}
                                            <StyledParagraph style={{ fontSize: '1rem' }}>What times are you most commonly available during the week? Times are in EST. Each meeting is 30 minutes long.</StyledParagraph>
                                            <Availability weeklyAvailability={weeklyAvailability} setWeeklyAvailability={setWeeklyAvailability} edit={true} isBottomLoading={false} />
                                        
                                            <StyledFormGroup className="mt-3">
                                                <StyledFormLabel>How can Prospinity help you?</StyledFormLabel>
                                                <div>



                                                {goals.map((goal) => (
                                                    <div>
                                                    <StyledFormCheck
                                                    inline
                                                    label={goal}
                                                    name="prospinityGoalPreference"
                                                    type="radio"
                                                    
                                                    onChange={() => handleGoalRadioChange(goal)}
                                                    checked={userInfo.goal === goal}
                                                />
                                                </div>
                                                ))}
                                                    
                                                
                                                </div>
                                            </StyledFormGroup>
                                        
                                        
                                        </>
                                    )

                                    }
                                    {step === 3 && (
                                        <>
                                            <StyledParagraph className='text-center' style={{ fontSize: '1.25rem' }}><strong>Pooling Preferences</strong></StyledParagraph>
                                            {/*<StyledParagraph className='text-center mt-0' style={{ fontSize: '0.75rem' }}>(Not limiting, just a tool for us to get a better idea of your preferences.)</StyledParagraph>*/}
                                            <StyledParagraph style={{ fontSize: '1rem' }}>If you want to be invited to potential success pools, the following questions allow us to get a better sense of your pool preferences, but can always be updated.</StyledParagraph>

                                            <StyledFormGroup>
                                                <StyledFormLabel><strong>If you had to choose, what would you prefer?</strong></StyledFormLabel>
                                                <div>
                                                    <StyledFormCheck
                                                        inline
                                                        label="Receiving an invite to a Prospinity curated pool"
                                                        name="prospinityPreference"
                                                        type="radio"
                                                        id={`inline-radio-1`}
                                                        onChange={() => handleRadioChange("Yes")}
                                                        checked={userInfo.prefer_prospinity === true}
                                                    />
                                                    <StyledFormCheck
                                                        inline
                                                        label="Forming your own private pools with friends"
                                                        name="prospinityPreference"
                                                        type="radio"
                                                        id={`inline-radio-2`}
                                                        onChange={() => handleRadioChange("No")}
                                                        checked={userInfo.prefer_prospinity === false}
                                                    />
                                                </div>
                                            </StyledFormGroup>
                                            <StyledFormGroup>
                                                <StyledFormLabel><strong>Ideal Pool Time Horizon</strong><FormTooltip message="This is how many years you would want a pool you are part of to last for." /></StyledFormLabel>
                                                <StyledRangeInput
                                                    min={0}
                                                    max={20}
                                                    step={5}
                                                    value={userInfo.preferred_horizon}
                                                    onChange={e => handleFieldChange({ field: 'preferred_horizon', val: Number(e.target.value) })}
                                                />
                                                <StyledParagraph className='text-center'>{userInfo.preferred_horizon} years</StyledParagraph>
                                            </StyledFormGroup>

                                            <StyledFormGroup>
                                                <StyledFormLabel><strong>Ideal Pool Rate</strong><FormTooltip message="This is the percentage of income over the threshold that you would like your pool to contribute. For example, if the pool has a 2% rate and $500k threshold, a member making $600k would contribute $2k." /></StyledFormLabel>
                                                <StyledRangeInput
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    value={userInfo.preferred_rate}
                                                    onChange={e => handleFieldChange({ field: 'preferred_rate', val: Number(e.target.value) })}
                                                />
                                                <StyledParagraph className='text-center'>{userInfo.preferred_rate}%</StyledParagraph>
                                            </StyledFormGroup>

                                            <StyledFormGroup>
                                                <StyledFormLabel><strong>Ideal Pool Threshold</strong><FormTooltip message="This is the threshold of income which is protected in the pool. Members of the pool only contribute a percentage of the income they make over the threshold. For example, if the pool has a 2% rate and $500k threshold, a member making $600k would contribute $2k." /></StyledFormLabel>
                                                <StyledRangeInput
                                                    min={0}
                                                    max={1000000}
                                                    step={250000}
                                                    value={userInfo.preferred_threshold}
                                                    onChange={e => handleFieldChange({ field: 'preferred_threshold', val: Number(e.target.value) })}
                                                />
                                                <StyledParagraph className='text-center'>{formatCurrency(userInfo.preferred_threshold)}</StyledParagraph>
                                            </StyledFormGroup>







                                        </>
                                    )}
                                    {step == 4 && <><StyledFormGroup controlId="formInput">
                                        <StyledParagraph className='text-center' style={{ fontSize: '1.25rem' }}><strong>Intro</strong></StyledParagraph>


                                        <StyledParagraph>These serve as your introduction to potential poolmates and people you are meeting.</StyledParagraph>
                                        <ProfilePictureUpload />
                                        <FormInput label="LinkedIn Profile URL" dataArr={userInfo} handleFieldChange={handleFieldChange} fieldName='linkedin_url' select={false} options={null} extra={<span className="text-danger">*</span>}/>




                                        <StyledFormLabel>Bio</StyledFormLabel>
                                        <StyledTextArea
                                            placeholder="John Doe is a senior at Yale studying Economics. He received a return offer from General Atlantic after completing a summer internship in New York in 2023. Prior to that, he interned at a search fund in Chicago. Beyond his professional pursuits, he loves ski mountaineering and hiking."
                                            as="textarea"
                                            name="bio"
                                            value={userInfo.bio}
                                            onChange={e => handleFieldChange({ field: 'bio', val: e.target.value })}
                                            rows={4}
                                        />
                                    </StyledFormGroup></>}
                                    <StyledButton type="submit" className="mt-3 w-100">
                                        {step < 4 ? <>Next</> : <>Submit and Finish Account</>}

                                    </StyledButton>
                                </StyledForm>
                                <StyledButton onClick={handlePrevious} className="mt-3 w-100">
                                    {step == 1 ? <>Logout</> : <>Previous</>}

                                </StyledButton>

                            </StyledCard.Body>
                        </StyledCard>
                    </Col>
                </Row>
            </Container>
            <StyledModal show={finishModalShow} onHide={handleClose} backdrop="static"
                keyboard={false} centered>
                <StyledModal.Header closeButton>
                    <StyledModal.Title>Welcome to Prospinity!</StyledModal.Title>
                </StyledModal.Header>
                <StyledModal.Body>
                    {introModalStep == 1 && 
                    <>

                    

                    <StyledParagraph><strong>Meet: </strong>Join AI-matched 1:1 meetings, connecting you with peers based on your background and goals.</StyledParagraph>
                    
                    <StyledParagraph><strong>Pool: </strong>Create and join Success Pools to hedge your career risk and invest in your peers.</StyledParagraph>
                    
                    </>
                    }
                    {introModalStep == 2 && <><StyledParagraph>Please take <strong>five minutes</strong> and finish your account to get started.</StyledParagraph>

                        <StyledParagraph>By finishing an account, there is <strong>no commitment</strong> to join a pool or take a meeting. You will simply receive access to our pooling and meeting ecosystem and get to choose which pools and meetings you join.</StyledParagraph>

                        <StyledParagraph>Please also fill out a brief bio (2-3 lines) about your interests and work experience, so that we can invite you to meetings and pools that are a good fit. This can always be updated later.</StyledParagraph></>}


                </StyledModal.Body>
                <StyledModal.Footer>
                    <div>
                        <StyledButton variant="secondary" onClick={handleLeft} className='mx-3'>
                            {introModalStep == 1 && <>Skip</>}
                            {introModalStep == 2 && <>Back</>}


                        </StyledButton>
                        <StyledButton variant="secondary" onClick={handleRight} className='mx-3'>
                            {introModalStep == 1 && <>Next</>}
                            {introModalStep == 2 && <>Close</>}
                        </StyledButton>




                    </div>

                </StyledModal.Footer>
            </StyledModal>

            <StyledModal show={message !== ''} centered>
                <StyledModal.Header>Finishing Your Account...</StyledModal.Header>


                <StyledModal.Body>{message}</StyledModal.Body>
                <StyledModal.Footer>
                    <StyledButton onClick={() => {

                        setMessage('');
                    }}>Fix</StyledButton>

                </StyledModal.Footer>

            </StyledModal>

        </ Track>


    );
}

export default FinishRegistration;
