import { Nav, Form, FormControl, Row, Col, Container, InputGroup, Dropdown, Card, Stack, Button, FormGroup, FormLabel } from 'react-bootstrap';
import { StyledFormControl, StyledFormSelect, StyledFormGroup, StyledFormLabel } from './StyledBootstrap';


export function FormInput({label, dataArr, handleFieldChange, fieldName, select, options, extra=""}) {
    let content;
    if (select == true) {
        content = (
            <StyledFormSelect
                value={dataArr[fieldName]}
                onChange={e => handleFieldChange({ field: fieldName, val: e.target.value })}
            >
                <option>Select Your {label}</option>
                {options.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </StyledFormSelect>
        );
    }
    else {
        content = (
            <StyledFormControl
                type="text"
                placeholder={"Enter " + label}
                value={dataArr[fieldName]}
                onChange={e => handleFieldChange({ field: fieldName, val: e.target.value })}
            />
        );
    }
    return (
        <StyledFormGroup className="mb-3">
            <StyledFormLabel>{label}{extra}</StyledFormLabel>
            {content}
        </StyledFormGroup>
    );
}