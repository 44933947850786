import { StyledH2 } from "./StyledBootstrap";

function Payments() {

    return (
        <div className="text-center">
             <StyledH2>No Payments Due</StyledH2>
        </div>
    );
}

export default Payments;