import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { Chat, Channel, ChannelList, Window, MessageList, MessageInput, Thread } from 'stream-chat-react';
import './StreamChatLayout.css';
import { StyledButton } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { useAuth } from './AuthContext';
import { StreamChat } from 'stream-chat';
import { useMemo } from 'react';
import { CustomChannelPreview } from './CustomChannelPreview';
import { CustomChannelHeader } from './CustomChannelHeader';

const Messaging = () => {
  const { userFirstName, userLastName, userSlug, chatToken, profilePictureLink, activeChannel, setActiveChannel, isChatVisible, setChatVisible } = useAuth();
  const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  const themeMode = useTheme();
  const filters = { members: { $in: [userSlug] } };
  const options = { presence: true, state: true };
  const sort = { last_message_at: -1 };

  const [chatClient, setChatClient] = useState(null);
  const [viewingChannelList, setViewingChannelList] = useState(true);
  const messageListContainerRef = useRef(null);

  const initializeClient = useCallback(async () => {
    const client = StreamChat.getInstance(apiKey);
    const userObject = profilePictureLink != null && profilePictureLink !== "" ? { id: userSlug, name: `${userFirstName} ${userLastName}`, image: profilePictureLink} : { id: userSlug, name: `${userFirstName} ${userLastName}`};
    try {
      await client.connectUser(userObject, chatToken);
      setChatClient(client);
    } catch (error) {
      console.error('Connection error', error);
    }
  }, [apiKey, userSlug, userFirstName, userLastName, chatToken]);

  useEffect(() => {
    if (isChatVisible && !chatClient) {
      initializeClient();
    }

    return () => {
      if (chatClient) {
        chatClient.disconnectUser();
        setChatClient(null);
      }
    };
  }, [isChatVisible, chatClient, initializeClient]);

  useLayoutEffect(() => {
    if (messageListContainerRef.current) {
      messageListContainerRef.current.scrollTop = messageListContainerRef.current.scrollHeight;
    }
  }, [isChatVisible]);

  const toggleChat = () => {
    setChatVisible(!isChatVisible);
    setActiveChannel(null);
    setViewingChannelList(true); // Reset to channel list view when toggling chat visibility
  }

  const onChannelSelect = (channel) => {
    setActiveChannel(channel);
    setViewingChannelList(false); // Switch to message view when a channel is selected
  }

  const backToChannelList = () => {
    setViewingChannelList(true); // Switch back to channel list view
    setActiveChannel(null); // Clear the active channel
  }

  const channelListProps = useMemo(() => {
    const props = {
      sort,
      filters,
      options,
      showChannelSearch: true,
      additionalChannelSearchProps: { searchForChannels: true, popupResults: true },
      Preview: (props) => <CustomChannelPreview {...props} setActiveChannel={onChannelSelect} />,
      ...(activeChannel && { customActiveChannel: activeChannel })
    };
    return props;
  }, [sort, filters, options, activeChannel]);

  return (
    <>
      <StyledButton onClick={toggleChat} style={{ position: 'fixed', bottom: '2vh', right: 20, zIndex: 1000 }}>
        {isChatVisible ? 'Hide Chat' : 'Show Chat'}
      </StyledButton>

      {isChatVisible && chatClient && (
        <div style={{ position: 'fixed', bottom: 60, right: 20, maxWidth: '50vw', zIndex: 1000, border: '8px solid', borderColor: themeMode.surface, borderRadius: '15px', color: '#ccc', backgroundColor: themeMode.surface }}>
          <Chat client={chatClient} theme={themeMode.theme === 'dark' ? 'str-chat__theme-dark' : 'str-chat__theme-light'}>
            <div className='d-flex' style={{ height: '70vh' }}>
              {viewingChannelList ? (
                <ChannelList {...channelListProps} />
              ) : (
                <Channel channel={activeChannel}>
                  <Window>
                    <CustomChannelHeader setChatVisible={setChatVisible} backToChannelList={backToChannelList} />
                    <MessageList />
                    <MessageInput />
                  </Window>
                  <Thread />
                </Channel>
              )}
            </div>
          </Chat>
        </div>
      )}
    </>
  );
};

export default Messaging;
