import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { StyledBorderCard } from './StyledBootstrap';
import { useTracking } from 'react-tracking';
import { ProfilePicture } from './MiscComponents';

function UserPreviewCard({ preview_object }) {
    const { Track, trackEvent } = useTracking({ event: "User Preview" });

    const fullName = `${preview_object.first_name} ${preview_object.last_name}`;
    const majors = Array.isArray(preview_object.major_or_grad_program)
        ? preview_object.major_or_grad_program.join(', ')
        : preview_object.major_or_grad_program;
    const university = preview_object.university;
    const company = preview_object.company;

    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);
    const navigate = useNavigate();

    const titleSize = pictureSize * 0.15;
    const majorSize = pictureSize * 0.1;

    const handleUserClick = () => {
        trackEvent({ user: preview_object.slug });
        navigate('/dashboard/user/' + preview_object.slug);
    }

    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;
                const size = Math.min(cardWidth, cardWidth * 1.6) * 0.7; // Adjusted size when not hovered
                setPictureSize(size);
            }
        };

        updateCardSize();
        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, []);

    return (
        <Track>
            <StyledBorderCard
                ref={cardRef}
                onClick={handleUserClick}
                style={{
                    width: '8rem',
                    height: '13rem', // Fixed size when not hovered
                    transition: 'transform 0.3s ease',
                    transform: 'scale(1)',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                    
                }}
                onMouseEnter={() => {
                    cardRef.current.style.transform = 'scale(1.07)';
                    cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
                }}
                onMouseLeave={() => {
                    cardRef.current.style.transform = 'scale(1)';
                    cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
                }}
            >
                <StyledBorderCard.Body style={{ textAlign: 'center'}}>
                    <div className='d-flex justify-content-center'>
                        <ProfilePicture name={fullName} size={pictureSize} graduation_date={preview_object.graduation_date} picture_link={preview_object.profile_picture} />
                    </div>
                    <StyledBorderCard.Title
                        style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: `${titleSize}px`,
                            marginTop: '0.3em'
                        }}>
                        {fullName}
                    </StyledBorderCard.Title>
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            lineHeight: '1.2em',
                        }}>
                        {company === "" ? majors || 'Not Specified' : company || 'Not Specified'}
                    </StyledBorderCard.Text>
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            lineHeight: '1.2em',
                        }}>
                        {university || 'Not Specified'}
                    </StyledBorderCard.Text>
                </StyledBorderCard.Body>
            </StyledBorderCard>
        </Track>
    );
}

export default UserPreviewCard;
