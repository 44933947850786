//if you click out of it what happens

import React, { useState, useEffect, useRef } from 'react';

import { stringToColor } from './CriticalFunctions';
import axios from 'axios';
import { formatGraduationDate } from './CriticalFunctions';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { StyledDropdown, StyledForm, StyledFormGroup, StyledFormControl } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import {useTracking} from 'react-tracking';


const bubbleStyle = (color) => ({
    display: 'inline-block',  // Use flex layout
    padding: '5px 10px',
    margin: '5px',
    borderRadius: '15px',
    backgroundColor: color,
    color: 'black',
    fontSize: '0.8rem',
    cursor: 'pointer',
    wordWrap: 'break-word', // Allows text to wrap inside the bubble
    whiteSpace: 'normal',
});







function SearchUsers() {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null)

    const navigate = useNavigate();
    const { userSlug } = useAuth();
    const themeMode = useTheme();

    const {Track, trackEvent} = useTracking({event: "Search Users"});


    const customDropdownItemStyle = {
        backgroundColor: themeMode.surface, // Note the camelCase for the property
        border: 'none',
        display: 'block'
    };

    const fetchOptions = async (query) => {
        try {
            trackEvent({query: query});
            const response = await myAxiosInstance.get('/api/search-users/' + '?q=' + query);
            console.log(response);
            return response.data;


        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowDropdown(false); // Hide dropdown when clicking outside
                setInputValue('');
                setActiveSuggestionIndex(0);

            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);


    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (inputValue) {

                fetchOptions(inputValue).then(data => {
                    setSuggestions(data);
                });
            } else {
                setSuggestions([]);
            }
        }, 100); // 300ms debounce time

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [inputValue]);



    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setShowDropdown(true);
        setActiveSuggestionIndex(0);
    };

    const handleSelect = (item) => {
        setInputValue('');
        setActiveSuggestionIndex(0);
        setShowDropdown(false);
        openUser(item.slug);
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && suggestions.length > 0) {
            event.preventDefault();
            handleSelect(suggestions[activeSuggestionIndex]);
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
            setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
            setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
        }
    };

    const openUser = (slug) => {
        navigate('/dashboard/user/' + slug + '/');
    };

    return (
        <Track>
        <StyledForm className='mt-4'>
        <StyledFormGroup ref={wrapperRef}>   
            <StyledFormControl
                type="text"
                value={inputValue}
                placeholder={"Search Users"}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{minWidth: '320px'}}

            />
            
            <StyledDropdown show={showDropdown && suggestions.length > 0}>
                <StyledDropdown.Menu align={'start'} style={{
                        position: 'absolute', // Positioned absolutely within the new context
                        left: 0, // Align to the left edge of the positioning context
                        right: 0, // Align to the right edge of the positioning context
                        top: '100%', // Position just below the input field
                        width: 'auto', // You can adjust this width as needed
                        minWidth: '25rem',
                        maxWidth: '30rem',
                        overflowY: 'auto'
                    }}>
                    {suggestions.map((item, index) => (
                        <StyledDropdown.Item
                            key={index}
                            active={index === activeSuggestionIndex}
                            onClick={() => handleSelect(item)}
                            style={customDropdownItemStyle}
                            className={` ${index === activeSuggestionIndex ? 'active-suggestion' : ''}`}
                        >
                            <div style={
                                {
                                    ...bubbleStyle(stringToColor(item.first_name + " " + item.last_name)),
                                    cursor: 'pointer',
                                    border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
                                    boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none',

                                }}>
                                {item.first_name + " " + item.last_name + " '" + formatGraduationDate(item.graduation_date) + ", " + item.university + ", " + item.major_or_grad_program.map(program => program).join(", ")}
                            </div>
                        </StyledDropdown.Item>
                    ))}
                </StyledDropdown.Menu>
            </StyledDropdown>
        </StyledFormGroup>
        </StyledForm>
        </Track>
    );
}

export default SearchUsers;