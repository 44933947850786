import { CallingState, StreamCall, StreamVideo, StreamVideoClient, useCall, useCallStateHooks, User, StreamTheme, SpeakerLayout, CallControls } from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';


export default function TestVideoCall() {


  const apiKey = 'mmhfdzb5evj2'; // the API key can be found in the "Credentials" section
  const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiSGFuX1NvbG8iLCJpc3MiOiJodHRwczovL3Byb250by5nZXRzdHJlYW0uaW8iLCJzdWIiOiJ1c2VyL0hhbl9Tb2xvIiwiaWF0IjoxNzE2NDg5NTg1LCJleHAiOjE3MTcwOTQzOTB9.LQT2MS_SlRD1a-eNCsfiAGkXXfjDuCsjhY7NHG4hqjk'; // the token can be found in the "Credentials" section
  const userId = 'Han_Solo'; // the user id can be found in the "Credentials" section
  const callId = 'YEjvepjl4KTc'; // the call id can be found in the "Credentials" section
  
  // set up the user object
  const user = {
    id: userId,
    name: 'Oliver',
    image: 'https://getstream.io/random_svg/?id=oliver&name=Oliver',
  };
  
  const client = new StreamVideoClient({ apiKey, user, token });
  const call = client.call('default', callId);
  call.join({ create: true });



  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <MyUILayout />
      </StreamCall>
    </StreamVideo>
  );
}

export const MyUILayout = () => {
    const { useCallCallingState } = useCallStateHooks();
    const callingState = useCallCallingState();
  
    if (callingState !== CallingState.JOINED) {
      return <div>Loading...</div>;
    }
  
    return (
      <StreamTheme>
        <SpeakerLayout participantsBarPosition='bottom' />
        <CallControls />
      </StreamTheme>
    );
  };