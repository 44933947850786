// In ProtectedRoute.js
//import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';



import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SignIn from './SignIn';
import SignUp from './SignUp';
import StyledModalClose from './StyledModalClose';
import { StyledModal, StyledButton, StyledParagraph } from './StyledBootstrap';
import { useNavigate } from 'react-router-dom';
import PublicHome from './PublicHome';

const SignInSignUpModal = ({ show, handleClose }) => {
    const navigate = useNavigate();

    return (
        <StyledModalClose show={show} onHide={handleClose} centered title={"Sign In To Continue..."}>
            <StyledModal.Body>
                <StyledParagraph>
                    Please sign in before continuing. If you don't have an account, please create one.
                </StyledParagraph>
            </StyledModal.Body>
            <StyledModal.Footer>
                <StyledButton onClick={() => navigate('/signin')} className='mx-3'>
                    Sign In
                </StyledButton>
                <StyledButton onClick={() => navigate('/signup')}>
                    Sign Up
                </StyledButton>

            </StyledModal.Footer>
        </StyledModalClose>
    );
};



export const ProtectedRoute = ({ children, requiredStage = null }) => {
    const { token, accountStage } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();


    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true)
    };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/home');
    };


    if (!token) {
        if (!showModal) {
            handleShowModal();
        }

        return (
            <>
                <PublicHome />
                {showModal && <SignInSignUpModal show={showModal} handleClose={handleCloseModal} />}
            </>);






        // Redirect to sign in if not authenticated
        //return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    /*if (accountStage > requiredStage) {
        return <Navigate to="/dashboard" replace />;
    }*/

    if (requiredStage !== null && accountStage !== requiredStage) {
        // Redirect to an appropriate page based on account stage

        if (accountStage == '1') {
            return <Navigate to="/finish-registration" replace />;
        }
        if (accountStage == '3') {
            return <Navigate to="/dashboard" replace />;
        }

    }

    return children;
};
