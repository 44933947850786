import React, { useState, useEffect } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledFormGroup, StyledFormControl, StyledFormLabel } from './StyledBootstrap';
import { useTheme } from 'styled-components';

const bubbleStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  margin: '5px',
  borderRadius: '15px',
  backgroundColor: '#d3d3d3',
  color: '#000000',
  fontSize: '0.8rem',
  cursor: 'pointer',
};

const closeButtonStyle = {
  marginLeft: '0.3rem',
  cursor: 'pointer'
};

const suggestionListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
};

function MultiSelectEmails({ value, onChange, label }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState(value);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  const themeMode = useTheme();

  useEffect(() => {
    // In a real application, you might want to fetch email suggestions here.
    // For simplicity, we'll just provide an empty array.
    setSuggestions([]);
  }, []);

  const handleInputChange = (event) => {
    const val = event.target.value;
    setInputValue(val);
    // In a real application, you'd probably want to fetch email suggestions based on `val`.
    // For simplicity, we'll just provide an empty array.
    if (val)
    {
      setSuggestions([val]);
    }
    else{
      setSuggestions([]);
    }
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (suggestions.length > 0)
      {
        handleSelectEmail(suggestions[activeSuggestionIndex]);
      }
      
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
    }
  };

  const handleSelectEmail = (email) => {
    if (!selectedEmails.includes(email)) {
      const updatedEmails = [...selectedEmails, email];
      setSelectedEmails(updatedEmails);
      onChange(updatedEmails);
      setInputValue('');
      setSuggestions([]);
    }
  };

  const handleRemoveEmail = (email) => {
    const updatedEmails = selectedEmails.filter(e => e !== email);
    setSelectedEmails(updatedEmails);
    onChange(updatedEmails);
  };

  return (
    <StyledFormGroup className='mb-3'>
      <StyledFormLabel>{label}</StyledFormLabel>
      <div>
        <StyledFormControl
          type="text"
          value={inputValue}
          placeholder="Enter valid emails and press Enter"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {suggestions.length > 0 && (
          <ul style={suggestionListStyle}>
            {suggestions.map((email, index) => (
              <li key={index} onClick={() => handleSelectEmail(email)}>
                <span style={{...bubbleStyle, cursor: 'pointer',
                    border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
                    boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none'}}>{email}</span>
              </li>
            ))}
          </ul>
        )}
        <div>
          {selectedEmails.map((email, index) => (
            <span key={index} style={bubbleStyle}>
              {email}
              <span style={closeButtonStyle} onClick={() => handleRemoveEmail(email)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
        </div>
      </div>
    </StyledFormGroup>
  );
}

export default MultiSelectEmails;




{/*import React, { useState } from 'react';
import { FormControl, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledFormGroup, StyledFormControl, StyledFormLabel, StyledButton } from './StyledBootstrap';
import { useTheme } from 'styled-components';





const closeButtonStyle = {
  marginLeft: '0.3rem',
  cursor: 'pointer'
};

const isValidEmail = (email) => {
  // Simple email validation pattern
  return /\S+@\S+\.\S+/.test(email);
}

function MultiSelectEmails({ value, onChange, label }) {
  const [inputValue, setInputValue] = useState('');
  const [selectedEmails, setSelectedEmails] = useState(value);
  const themeMode = useTheme();

  const bubbleStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    margin: '5px',
    borderRadius: '15px',
    backgroundColor: '#d3d3d3',
    color: '#000000',
    fontSize: '0.8rem',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddEmail = () => {
    if (isValidEmail(inputValue) && !selectedEmails.includes(inputValue)) {
      const updatedEmails = [...selectedEmails, inputValue];
      setSelectedEmails(updatedEmails);
      onChange(updatedEmails);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (email) => {
    const updatedEmails = selectedEmails.filter(e => e !== email);
    setSelectedEmails(updatedEmails);
    onChange(updatedEmails);
  };

  return (
    <StyledFormGroup className='mb-3'>
      <StyledFormLabel>{label}</StyledFormLabel>
      <div>

        <StyledFormControl
          type="text"
          value={inputValue}
          placeholder="Enter a valid email and press Enter"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />


        <div>
          {selectedEmails.map((email, index) => (
            <span key={index} style={bubbleStyle}>
              {email}
              <span
                style={closeButtonStyle}
                onClick={() => handleRemoveEmail(email)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
        </div>
      </div>
    </StyledFormGroup>
  );
}

export default MultiSelectEmails;*/}





{
/*
import React, { useState } from 'react';
import { FormControl, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledFormGroup, StyledFormControl, StyledFormLabel, StyledButton } from './StyledBootstrap';
import { useTheme } from 'styled-components';





const closeButtonStyle = {
  marginLeft: '0.3rem',
  cursor: 'pointer'
};

const isValidEmail = (email) => {
  // Simple email validation pattern
  return /\S+@\S+\.\S+/.test(email);
}

function MultiSelectEmails({ value, onChange, label }) {
  const [inputValue, setInputValue] = useState('');
  const [selectedEmails, setSelectedEmails] = useState(value);
  const themeMode = useTheme();

  const bubbleStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    margin: '5px',
    borderRadius: '15px',
    backgroundColor: '#d3d3d3',
    color: '#000000',
    fontSize: '0.8rem',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddEmail = () => {
    if (isValidEmail(inputValue) && !selectedEmails.includes(inputValue)) {
      const updatedEmails = [...selectedEmails, inputValue];
      setSelectedEmails(updatedEmails);
      onChange(updatedEmails);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (email) => {
    const updatedEmails = selectedEmails.filter(e => e !== email);
    setSelectedEmails(updatedEmails);
    onChange(updatedEmails);
  };

  return (
    <StyledFormGroup className='mb-3'>
      <StyledFormLabel>{label}</StyledFormLabel>
      <div>
      <Row>
      <Col xs={11}>
        <StyledFormControl
          type="text"
          value={inputValue}
          placeholder="Enter a valid email and click Add Email or press Enter"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </Col>
      <Col xs={1} className='px-0'>
        <StyledButton onClick={handleAddEmail}>Add</StyledButton>
        </Col>
        </Row>
        <div>
          {selectedEmails.map((email, index) => (
            <span key={index} style={bubbleStyle}>
              {email}
              <span
                style={closeButtonStyle}
                onClick={() => handleRemoveEmail(email)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
        </div>
      </div>
    </StyledFormGroup>
  );
}

export default MultiSelectEmails;
*/
}