// In AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { myAxiosInstance } from './axiosConfig';
import { Mixpanel } from './Mixpanel';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('userToken'));
    const [accountStage, setAccountStage] = useState(null);
    const [userSlug, setUserSlug] = useState(null);
    const [userFirstName, setUserFirstName] = useState(null);
    const [userLastName, setUserLastName] = useState(null);
    const [userGraduationDate, setUserGraduationDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(1);
    const [firstTime, setFirstTime] = useState(null);
    const [chatToken, setChatToken] = useState(null);
    const [profilePictureLink, setProfilePictureLink] = useState(null);
    const [availabilityCompleted, setAvailabilityCompleted] = useState(false);
    const [activeChannel, setActiveChannel] = useState(null);
    const {showBoundary} = useErrorBoundary();
    const [isChatVisible, setChatVisible] = useState(false);


    //const navigate = useNavigate();


    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setIsLoading(true);
                //await new Promise(resolve => setTimeout(resolve, 2000));
                const response = await myAxiosInstance.get('/api/basic-user-info/');
                console.log("BASIC USER INFO");
                console.log(response);
                setUserSlug(response.data.slug);
                setAccountStage(response.data.stage);
                setUserFirstName(response.data.first_name);
                setUserLastName(response.data.last_name);
                setUserGraduationDate(response.data.graduation_date);
                setFirstTime(response.data.first_time_logging_in);
                setChatToken(response.data.chat_token);
                setAvailabilityCompleted(response.data.availability_completed);
                setProfilePictureLink(response.data.profile_picture);
                Mixpanel.identify(response.data.email);
                Mixpanel.people.set({
                    $first_name: response.data.first_name,
                    $last_name: response.data.last_name,
                    $email: response.data.email
                  });
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsLoading(false); // Set isLoading to false regardless of success or error
            }
        };
        if (token) {
            getUserDetails();
        }
        else{
            setIsLoading(false);
        }

    }, [token, reload]); // Include slug in the dependency array

    function handleLogin(newToken) { //make this reloads
        console.log('handleLogin called with token:', newToken);
        setToken("logged in");
        localStorage.setItem('userToken', "logged_in");
        window.location.href = '/dashboard';
        //navigate("/dashboard");
    }

    async function handleLogout() { //make this reload
        console.log('handleLogout called');
        try {
            localStorage.removeItem('userToken');
            setToken(null);
            const response = await myAxiosInstance.post('/api/logout/');
            console.log(response);
            Mixpanel.reset();
            //navigate('/signin');
            window.location.href = '/signin';
        }
        catch (error) {
            showBoundary(error);
        }

    }

    // Methods to update isAuthenticated and accountStage...

    return (
        <AuthContext.Provider value={{ token, handleLogin, handleLogout, userSlug, accountStage, isLoading, reload, setReload, userFirstName, userLastName, userGraduationDate, firstTime, chatToken, availabilityCompleted, profilePictureLink, setProfilePictureLink, activeChannel, setActiveChannel, isChatVisible, setChatVisible }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
