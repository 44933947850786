import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { StyledButton, StyledH4, StyledParagraph, StyledCard } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import Loading from './Loading';
import { Container, Row, Col } from 'react-bootstrap';

function EmailVerificationSent({children})
{
    const themeMode = useTheme();
    return (

        <Container fluid style={{ height: '100vh' ,backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
            <Row className='justify-content-center'>
                <Col className='col-lg-4 col-md-6'>

                    <StyledCard>
                        <StyledCard.Body>
                        <Row className='justify-content-center mb-3'>
                                <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>
                                </Col>
                            </Row>
                        <div className='text-center'>
                        {children}

                        </div>
            </StyledCard.Body>
                    </StyledCard>
                </Col>
            </Row>

        </Container>

    );
}

export default EmailVerificationSent;