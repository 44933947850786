
import EmailVerificationSent from './EmailVerificationSent';
import { StyledParagraph } from './StyledBootstrap';

function ContractNotLive() {


    return (
        
        
        <EmailVerificationSent><StyledParagraph className='mt-3'>You are now in the signing period! The Success Pooling Agreement for Prospinity ONE will be live at 9:00 a.m. EST on March 6th. Stay tuned!</StyledParagraph></ EmailVerificationSent>
        );
}

export default ContractNotLive;


