import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';




function FormTooltip({ message }) {
    return (
        <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-right">{message}</Tooltip>}
        >
            <span style={{ marginLeft: 5 }}>
            <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
        </OverlayTrigger>
    );
}


export default FormTooltip;