import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'; // import useNavigate if using React Router v6
import PoolDeadlineTimer from "./PoolDeadlineTimer";
import { formatToDollar } from "./CriticalFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import CustomModal from './CustomModal';
import { apiP } from './CriticalFunctions';
import UserPreviewCard from './UserPreviewCard';
import ManagePool from './ManagePool';
import Loading from './Loading';
import './Pool.css';
import { useAuth } from './AuthContext';
import { useErrorBoundary } from "react-error-boundary";
import { StyledButton, StyledCard, StyledListGroup } from './StyledBootstrap';
import { pastDeadline } from './CriticalFunctions';
import { myAxiosInstance } from './axiosConfig';
import { StyledH3 } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { stylePoolSlug, convertPackageToGreek } from './CriticalFunctions';



//change invitee look
//weird total spots going down error

function PoolPage() {
    const { slug } = useParams();
    const [reload, setReload] = useState(1);
    const [userInfo, setUserInfo] = useState(null);
    const [publicDetails, setPublicDetails] = useState(null);
    const [privateDetails, setPrivateDetails] = useState(null);
    const [extraPrivateDetails, setExtraPrivateDetails] = useState(null);
    const [captainInfo, setCaptainInfo] = useState(null);
    const [otherUsers, setOtherUsers] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [poolMessageLoading, setPoolMessageLoading] = useState(false);

    const themeMode = useTheme();

    const location = useLocation();

    const [editPoolData, setEditPoolData] = useState(null);
    const [delPool, setDelPool] = useState(false);
    const [removeInvitees, setRemoveInvitees] = useState([]);
    const [addInvitees, setAddInvitees] = useState([]);
    const [inviteEmails, setInviteEmails] = useState([]);

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [manageReload, setManageReload] = useState(false);

    const [signingInterface, setSigningInterface] = useState(null);



    const handlePoolChatButtonClick = async () => {
        try {
            console.log(slug);
            setPoolMessageLoading(true);
            setActiveChannel(slug);
            setChatVisible(true);

        }
        catch (error)
        {
            console.error('Error setting up channel', error.response.data);

        }
        finally{
            setPoolMessageLoading(false);
        }
        


    };



    const { showBoundary } = useErrorBoundary();

    const { accountStage, setActiveChannel, setChatVisible } = useAuth();

    const navigate = useNavigate();

    const buttonOptions = [
        { buttonText: 'Accept Invite', action: async () => { apiPoolAction('accept-pool-invite', "post", null, true) }, title: 'Accept Invite', modalButtonText: 'Accept Invite', modalText: 'Please confirm you would like to accept the invite to this pool.' },
        { buttonText: 'Invite Accepted', action: async () => { apiPoolAction('leave-pool', "post", null, true) }, title: 'Invite Accepted', modalButtonText: 'Leave', modalText: 'You have accepted the invite to this pool. Only click "Leave" below if you are sure you would like to leave the pool.' },
        { buttonText: 'View and Sign', action: () => signExternally(), title: 'Sign', modalButtonText: 'View Externally', modalText: 'Please sign the Success Pooling Agreement for this pool to be included.' },
        { buttonText: 'Manage Pool', action: async () => { handleManagePoolModalButtonClick() }, title: 'Manage Pool', modalButtonText: 'Save Changes to Pool', modalText: 'Please review your changes carefully before saving them.' },
        { buttonText: 'View Signed Contract', action: () => signExternally(), title: 'View Signed Contract', modalButtonText: 'View Signed Contract', modalText: 'Here is the signed contract.' },
        { buttonText: 'Verify Account', action: () => navigate("/dashboard/settings"), title: 'Verify Account Before Signing', modalButtonText: 'Go to Settings', modalText: 'Please verify your identity before signing the contract.' },
        {
            buttonText: 'Request',
            action: () => {
                // Assuming captainInfo contains the email address of the captain
                const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
                const captainName = captainInfo ? captainInfo.first_name : "Captain";
                const subject = encodeURIComponent("Request to Join Pool ");
                const body = encodeURIComponent("Dear " + captainName + ",\n\nI would like to request to join your pool. Please let me know if there's any further information you need from me.\n\nBest regards,");
                window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
            },
            title: 'Request to Join Pool',
            modalButtonText: 'Email Captain',
            modalText: 'Click here to draft an email to the captain of this pool. Feel free to frame the request however you would like.'
        },
        {
            buttonText: 'Evaluate',
            action: () => {
                // Assuming captainInfo contains the email address of the captain
                const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
                const captainName = captainInfo ? captainInfo.first_name : "Captain";
                const subject = encodeURIComponent("Evaluation Period Request ");
                const body = encodeURIComponent("Dear " + captainName + ",\n\n[Request]\n\nBest regards,");
                window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
            },
            title: 'Pool is in Evaluation Period',
            modalButtonText: 'Email Captain',
            modalText: 'Click here to email the captain of this pool if you have a comment, question, or late request to join during the evaluation period.'
        }     ];
    const [buttonOptionsIndex, setButtonOptionsIndex] = useState(null);

    useEffect(() => {
        const getPoolData = async () => {
            try {
                console.log("I am running again here1");
                console.log("ACCount STAGE: " + accountStage);
                setModalShow(false);
                
                setModalMessage(null);
                setButtonOptionsIndex(null);
                setSigningInterface(null);

                setIsBottomLoading(true);
                const response = await myAxiosInstance.get('/api/pools/' + slug + '/');
                console.log("I am running again here2");

                const data = response.data;
                setUserInfo(data.user_info);
                setPublicDetails(data.public_details);
                setPrivateDetails(data.hasOwnProperty('private_details') ? data.private_details : null);
                setExtraPrivateDetails(data.hasOwnProperty('extra_private_details') ? data.extra_private_details : null);
                setCaptainInfo(data.hasOwnProperty('captain_info') ? data.captain_info : null);
                setOtherUsers(data.hasOwnProperty('other_users') ? data.other_users : []);

                let currentIndex = 6; // Updated logic using the local variable
                if (data.user_info.status === "invited_not_accepted") {
                    console.log("cr");
                    currentIndex = 0;
                } else if (data.user_info.status === "invited_accepted") {
                    if (data.user_info.is_captain) {
                        currentIndex = 3;
                    } else {
                        currentIndex = 1;
                    }
                } else if (data.user_info.status === "accepted_not_signed") {
                    if (accountStage == 3) {
                        if (data.private_details.evaluate)
                        {
                            currentIndex = 7;
                        }
                        else{
                            currentIndex = 2;
                        }
                        
                    }
                    else {
                        currentIndex = 5;
                    }

                } else if (data.user_info.status === "accepted_signed") {
                    currentIndex = 4;
                }
                setButtonOptionsIndex(currentIndex); // Setting the index after determination
                if (reload == 1 && location.state && location.state.actionButtonClicked) {
                    handleButtonClick(currentIndex);
                }
                console.log("I am running again here3");
                console.log(data);


            } catch (error) {
                console.error('Error fetching pool details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getPoolData();
    }, [slug, reload]);


    const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
    {
        setReload(reload + 1);
    };

    const handleCaptainClick = () => {
        if (captainInfo) {
            navigate('/dashboard/user/' + captainInfo.slug)
        }
    }

    const handleButtonClick = (tempIndex) => //change this
    {
        if (tempIndex == 2 || tempIndex == 4)
        {
            console.log("BUTTON LCIBK URL");
            //setSigningInterface("temp");
            getInterfaceURL();
        }
        console.log("boi" + buttonOptionsIndex);
        setModalShow(true);
    }


    

    const handleClose = () => {
        setModalShow(false)
        setModalMessage(null);
        setManageReload(!manageReload);
    };


    const handleViewModalButtonClick = () => //change this
    {
        setModalShow(false);
    }

    const getInterfaceURL = async () => {
        let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
        console.log(response);
        setSigningInterface(response.data.document_link);
    } 

    const signExternally = async () => {
        setModalMessage(<Loading />);
        let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
        const newWindow = window.open(response.data.document_link, '_blank', 'noopener,noreferrer');
        //const newWindow = window.open("https://app.prospinity.com/contract-not-live", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        setModalMessage(null);

    }

    /////FIX REGISTRATION
    const apiPoolAction = async (endpoint, requestType, data, shouldReload) => {
        return apiP(data, endpoint + "/" + slug + "/", setModalMessage, "Done!", null, null, shouldReload, reload, setReload, requestType);
    }

    const handleManagePoolModalButtonClick = async () => {
        if (delPool) {
            const response = await apiPoolAction('update-delete-pools', "delete", null, false);
            if (response) {
                navigate('/dashboard/home');
            }

        }
        else { //determine order of this
            let final_users = editPoolData.invite_emails;
            if (removeInvitees.length != 0) {
                const removedUsers = removeInvitees.map(invitee => invitee.email);
                final_users = final_users.filter(item => !removedUsers.includes(item));
            }
            if (addInvitees.length != 0) {
                const addedUsers = addInvitees.map(invitee => invitee.email);
                final_users = [...final_users, ...addedUsers];
            }
            console.log("final users: " + final_users);
            const data = { ...editPoolData, invite_emails: [...final_users, ...inviteEmails] };
            const response = await apiPoolAction('update-delete-pools', "put", data, false);
            if (response) {
                setReload(reload + 1);
                navigate('/dashboard/pool/' + response.data.slug + "/");
            }
            if (response == null) {
                //setManageReload(!manageReload);
            }
        }

    }





    return (
        isBottomLoading ?
            <Loading />
            :
            <>
                <Row className='justify-content-center'>
                    <Col className='col-md-9'>
                        <StyledCard className='mb-3'>

                            <StyledCard.Header className="d-flex justify-content-between align-items-center">
                                {/* Left content (icon or another component) for visual balance */}
                                <div style={{ flex: 1 }}>
                                    <h3>
                                        <strong style={{ color: themeMode.secondary }}>
                                            {publicDetails.pool_package !== 'Custom' ? convertPackageToGreek(publicDetails.pool_package) : ""}
                                        </strong> </h3>
                                </div>

                                {/* Middle content (title), centered visually and structurally */}
                                <h3 style={{ color: themeMode.onPrimary, textAlign: 'center', flex: 2 }}>
                                    <strong>

                                        {privateDetails ? privateDetails.name : stylePoolSlug(publicDetails.slug.toUpperCase())}
                                    </strong>
                                </h3>

                                {/* Right content (button), aligned to the right */}
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    {userInfo.status==='not_associated' ? <></> : <StyledButton className='mr-2' onClick={handlePoolChatButtonClick}>{poolMessageLoading ? <Loading /> : "Chat"}</StyledButton>}
                                    {buttonOptionsIndex !== null && (
                                        <StyledButton onClick={() => handleButtonClick(buttonOptionsIndex)}>
                                            {buttonOptions[buttonOptionsIndex].buttonText}
                                        </StyledButton>
                                    )}
                                </div>
                            </StyledCard.Header>



                            <StyledCard.Body>
                                <StyledListGroup>


                                    <StyledListGroup.Item>
                                        <Row className='justify-content-center space-between'>
                                            {/*extraPrivateDetails == null || privateDetails.evaluate ? <></> :
                                                <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
                                                    <strong>Activation</strong>
                                                    <div>{extraPrivateDetails.percent_signitures_to_activate}%</div>
                                    </Col>*/}
                                            {/* AMOLI CHANGE */}
                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4} onClick={handleCaptainClick}> {/* AMOLI CHANGE */}
                                                <h6 style={{ color: themeMode.onSurface, textAlign: 'center', flex: 2 }}> {/* AMOLI CHANGE */}
                                                <strong>Captain</strong>             
                                                </h6>
                                                <p style={{ color: themeMode.onSurface, textAlign: 'center', flex: 2 }}> {/* AMOLI CHANGE */}
                                                <div className='captain-name'>{captainInfo ? captainInfo.first_name + " " + captainInfo.last_name : "Private"} {userInfo.is_captain && <FontAwesomeIcon icon={faStar} />}</div>
                                                </p>
                                            </Col>
                                            {privateDetails.evaluate ? 
                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                <strong>Status: </strong>
                                                <>Evaluation Period</>
                                            </Col> : 
                                            
                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                
                                                <h6 style={{ color: themeMode.onSurface, textAlign: 'center', flex: 2 }}> {/* AMOLI CHANGE */}
                                                {pastDeadline(publicDetails.sign_deadline) ? 
                                                <strong>Status </strong> : pastDeadline(publicDetails.invitation_deadline) ? <strong>Sign Deadline</strong> : <strong>Invitation Deadline</strong>}
                                                </h6>
                                                <p style={{ color: themeMode.onSurface, textAlign: 'center', flex: 2 }}> {/* AMOLI CHANGE */}
                                                <PoolDeadlineTimer invitationDeadline={publicDetails.invitation_deadline} signDeadline={publicDetails.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>
                                                </p>
                                </Col>}

                                        </Row>
                                    </StyledListGroup.Item>

                                    <StyledListGroup.Item>
                                        <Row className='justify-content-center'>
                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                <h6 style={{ color: themeMode.onSurface, textAlign: 'center', flex: 1 }}> {/* AMOLI CHANGE */}
                                                <strong>Percentage</strong>
                                                </h6>
                                                <p style={{color: themeMode.onSurface, textAlign:'center', flex: 1}}> {/* AMOLI CHANGE */}
                                                <div>{publicDetails.contribution_percent}%</div>
                                                </p>
                                            </Col>
                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                <h6 style={{ color: themeMode.onSurface, textAlign: 'center', flex: 1 }}> {/* AMOLI CHANGE */}
                                                <strong>Threshold</strong>
                                                </h6>
                                                <p style={{color: themeMode.onSurface, textAlign:'center', flex: 1}}> {/* AMOLI CHANGE */}
                                                <div>{formatToDollar(publicDetails.threshold)}</div>
                                                </p>
                                            </Col>

                                            <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                <h6 style={{ color: themeMode.onSurface, textAlign: 'center', flex: 1 }}> {/* AMOLI CHANGE */}
                                                <strong>Time Horizon</strong>
                                                </h6>
                                                <p style={{color: themeMode.onSurface, textAlign:'center', flex: 1}}> {/* AMOLI CHANGE */}
                                                <div>{publicDetails.time_horizon} years</div>
                                                </p>
                                            </Col>
                                            {publicDetails.minimum_income > 0 ?
                                                <Col className='d-flex flex-column justify-content-center align-items-center' xs={4}> {/* AMOLI CHANGE */}
                                                    <h6 style={{color: themeMode.onSurface, textAlign:'center', flex: 1}}> {/* AMOLI CHANGE */}
                                                    <strong>Minimum Income</strong>
                                                    </h6>
                                                    <p style={{color: themeMode.onSurface, textAlign:'center', flex: 1}}> {/* AMOLI CHANGE */}
                                                    <div>{formatToDollar(publicDetails.minimum_income)}</div>
                                                    </p>
                                                </Col> : <></>}
                                        </Row>
                                    </StyledListGroup.Item>
                                </StyledListGroup>

                            </StyledCard.Body>


                        </StyledCard>


                        {
                            userInfo.status === 'not_associated' ? <></> : privateDetails.evaluate ? <StyledCard><StyledCard.Body><>This pool is now locked in the evaluation period. Prospinity will either move the pool forward to the Signing Period, or assign you to a new pool later. You will be notified when a decision is made. Stay tuned!</></StyledCard.Body></StyledCard> :
                                <StyledCard>
                                    <StyledCard.Body>
                                        <StyledListGroup>
                                        {
                                            userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" ?
                                                userInfo.is_captain ?
                                                    <>
                                                        <StyledListGroup.Item>
                                                            <Row className='w-100 justify-content-center'>
                                                                <StyledCard.Title style={{ textAlign: 'center' }}>Accepted Invite</StyledCard.Title>
                                                                {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === true).map((otherUserInfo, index) => (
                                                                    <Col xs={6} sm={4} md={3} lg={2} key={index} style={{ marginBottom: '1em' }}>
                                                                        <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </StyledListGroup.Item>
                                                        <StyledListGroup.Item>
                                                            <Row className='w-100 justify-content-center'>
                                                                <StyledCard.Title style={{ textAlign: 'center' }}>Waiting</StyledCard.Title>
                                                                {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === false).map((otherUserInfo, index) => (
                                                                    <Col xs={6} sm={4} md={3} lg={2} key={index} style={{ marginBottom: '1em' }}>
                                                                        <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </StyledListGroup.Item>
                                                    </>
                                                    :
                                                    <StyledListGroup.Item>
                                                        <Row className='w-100 justify-content-center'>
                                                        <StyledCard.Title style={{ textAlign: 'center' }}>Invitees</StyledCard.Title>
                                                            {otherUsers.map((otherUserInfo, index) => (
                                                                <Col xs={6} sm={4} md={3} lg={2} key={index} style={{ marginBottom: '1em' }}>
                                                                    <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </StyledListGroup.Item>
                                                :
                                                <StyledListGroup.Item>
                                                    <Row className='w-100 justify-content-center'>
                                                        <StyledCard.Title style={{ textAlign: 'center' }}>Members</StyledCard.Title>
                                                        {otherUsers.map((otherUserInfo, index) => (
                                                            <Col xs={6} sm={4} md={3} lg={2} key={index} style={{ marginBottom: '1em' }}>
                                                                <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </StyledListGroup.Item>
                                        }

                                        </StyledListGroup>
                                    </StyledCard.Body>


                                </StyledCard>}
                    </Col>
                </Row >




                {buttonOptionsIndex != null ?
                    <CustomModal show={modalShow}
                        setShow={setModalShow}
                        title={buttonOptions[buttonOptionsIndex].title}
                        actionText={buttonOptions[buttonOptionsIndex].modalButtonText}
                        message={modalMessage}
                        setMessage={setModalMessage}
                        actionFunction={buttonOptions[buttonOptionsIndex].action}
                        handleClose={handleClose}
                    >
                    {
                        buttonOptionsIndex === 0 ? 
                        (
                            <>
                                By accepting this invite to view sections of our platform, you agree to keep all content, 
                                including the Success Pooling Agreement, confidential and acknowledge that it is the 
                                intellectual property of Prospinity. Accepting this invite does not constitute a binding legal 
                                commitment to any terms other than the confidentiality and intellectual property 
                                acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
                            </>
                        ) : 
                        buttonOptions[buttonOptionsIndex].modalText
                    }
                        {
                            buttonOptionsIndex == 3 ?

                                <ManagePool
                                    editPoolData={editPoolData}
                                    setEditPoolData={setEditPoolData}
                                    setModalMessage={setModalMessage}
                                    slug={slug}
                                    setDelPool={setDelPool}
                                    delPool={delPool}
                                    removeInvitees={removeInvitees}
                                    setRemoveInvitees={setRemoveInvitees}
                                    addInvitees={addInvitees}
                                    setAddInvitees={setAddInvitees}
                                    manageReload={manageReload}
                                    inviteEmails={inviteEmails}
                                    setInviteEmails={setInviteEmails}
                                ></ManagePool>

                                : <></>}

                                {buttonOptionsIndex == 2 || buttonOptionsIndex == 4 ? signingInterface ? (
                                    <div style={{height: '80vh', width: '100%'}}>
                                        <iframe src={signingInterface} style={{border: 'none', width: '100%', height: '100%'}} title="Signing Interface"></iframe>
                                    </div>
                                ) : <Loading/> : <></>}                                
                    </CustomModal>
                    :
                    <></>
                }
            </>

    );
}

export default PoolPage;