import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledModal, StyledDropdown, StyledButton, StyledH5, StyledNavLink, StyledParagraph, StyledFormGroup, StyledFormLabel, StyledFormControl } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import TutorialModal from './TutorialModal';
import Notifications from './Notifications';
import NavbarLargeScreen from './NavbarLargeScreen';
import NavbarSmallScreen from './NavbarSmallScreen';
import { apiP } from './CriticalFunctions';
import CustomModal from './CustomModal';
import Loading from './Loading';
import Messaging from './Messaging';
import EditAvailabilityModal from './EditAvailabilityModal';
import { useTracking } from 'react-tracking';

export default function Dashboard() {
    console.log("here is the token");
    console.log(localStorage.getItem('userToken'));
    const navigate = useNavigate();

    const { Track } = useTracking({ page: 'Dashboard' });

    const { firstTime, availabilityCompleted } = useAuth();

    const [showTutorial, setShowTutorial] = useState(firstTime); // To show or hide the tutorial modal
    const [tutorialPart, setTutorialPart] = useState(1); // To track the current part of the tutorial


    const [showReferModal, setShowReferModal] = useState(false);
    const [referModalMessage, setReferModalMessage] = useState(null);
    const [referModalEmail, setReferModalEmail] = useState('');
    const [referModalLoading, setReferModalLoading] = useState(true);
    const [referInvitesRemaining, setReferInvitesRemaining] = useState(null);
    const [referModalError, setReferModalError] = useState(false);

    const [promptAvailabilityModalShow, setPromptAvailabilityModalShow] = useState(false);

    useEffect(() => {
        if (!availabilityCompleted && !showTutorial)
        {
            setPromptAvailabilityModalShow(true);
        }

    }, [showTutorial])


    useEffect(() => {
        const getReferInvites = async () => {
            try {
                setReferModalLoading(true);
                setReferModalError(false);
                setReferInvitesRemaining(null);
                const response = await myAxiosInstance.get('/api/invite-platform/');
                console.log(response);
                setReferInvitesRemaining(response.data.invites_left);
            }
            catch (error) {
                console.log(error);
                setReferModalError(true);

            }
            finally {
                setReferModalLoading(false);
            }

        }
        if (showReferModal) {
            getReferInvites();
        }


    }, [showReferModal])


    const [whyModalStep, setWhyModalStep] = useState(1);
    const handleWhyLeft = () => {
        if (whyModalStep == 1) {
            handleWhyClose();
        }
        else {
            setWhyModalStep(1);
        }

    }
    const handleWhyRight = () => {
        if (whyModalStep == 1) {
            setWhyModalStep(2);
        }
        else {
            handleWhyClose();
        }

    }
    const [whyModalShow, setWhyModalShow] = useState(false);

    const handleWhyClose = () => {
        setWhyModalShow(false);
        setWhyModalStep(1);
    };


    async function markTutorialCompleted() {
        try {
            await myAxiosInstance.post('/api/complete-tutorial/');
        }
        catch (error) {
            showBoundary(error);
        }
    }



    async function referRequest() {
        try {
            const response = await apiP({ invite_email: referModalEmail }, "invite-platform/", setReferModalMessage, "Invited!", false, null, false, null, null, "put");
            //await myAxiosInstance.post('/api/invite-platform', {invite_email: email});
            if (response) {
                setShowReferModal(false);
                setReferModalEmail('');
                setReferModalMessage(null);
            }
        }
        catch (error) {
            showBoundary(error);
        }
    }

    const handleNextTutorial = () => {
        if (tutorialPart < 1) {
            setTutorialPart(tutorialPart + 1);
        } else {
            setShowTutorial(false); // Hide the tutorial after the last part
            markTutorialCompleted();

        }
    };


    const handleBackTutorial = () => {
        if (tutorialPart > 1) {
            setTutorialPart(tutorialPart - 1);
        }
    };

    const handleSkipTutorial = () => {
        setShowTutorial(false); // Hide the tutorial
        markTutorialCompleted();
    };

    const themeMode = useTheme();


    const { showBoundary } = useErrorBoundary();


    const isSmallScreen = useMediaQuery({ maxWidth: 1000 });
    console.log('Is small screen:', isSmallScreen);





    const navbarRef = useRef(null);
    const containerRef = useRef(null);

    /*useEffect(() => {
        const updateContainerHeight = () => {
            if (navbarRef.current && containerRef.current) {
                const navbarHeight = navbarRef.current.clientHeight;
                const windowHeight = window.innerHeight;
                const containerHeight = windowHeight - navbarHeight;
                containerRef.current.style.minHeight = `${containerHeight}px`;
            }
        };

        updateContainerHeight();
        window.addEventListener('resize', updateContainerHeight);

        // Cleanup listener
        return () => window.removeEventListener('resize', updateContainerHeight);
    }, []);*/
    useEffect(() => {
        const updateLayout = () => {
            const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
            const viewportHeight = window.innerHeight;
            const containerHeight = viewportHeight - navbarHeight;

            if (containerRef.current) {
                containerRef.current.style.marginTop = `${navbarHeight}px`; // Push content below the navbar
                containerRef.current.style.minHeight = `${containerHeight}px`; // Ensure it fills the remaining screen height
            }
        };

        updateLayout(); // Adjust layout on mount
        window.addEventListener('resize', updateLayout); // and on window resize

        return () => window.removeEventListener('resize', updateLayout); // Cleanup
    }, []); // Run once on mount













    return (
        <Track>
        <Container fluid className="px-0">
            <div ref={navbarRef} className='fixed-top-navbar'>
                {isSmallScreen ? <NavbarSmallScreen setShowReferModal={setShowReferModal} setWhyModalShow={setWhyModalShow} /> : <NavbarLargeScreen setShowReferModal={setShowReferModal} setWhyModalShow={setWhyModalShow} />}
            </div>
            <Container ref={containerRef} fluid className='py-3 d-flex flex-column justify-content-center' style={{ backgroundColor: themeMode.background }}>
                <Outlet />
                <Messaging />
            </Container>
            
            <TutorialModal
                show={showTutorial}
                tutorialPart={tutorialPart}
                onNext={handleNextTutorial}
                onSkip={handleSkipTutorial}
                onBack={handleBackTutorial}
            />
            <CustomModal title="Invite a Friend to the Prospinity Platform" actionText={"Invite"} actionFunction={referRequest} show={showReferModal} setShow={setShowReferModal} message={referModalMessage} setMessage={setReferModalMessage} handleClose={() => setShowReferModal(false)}>
                {referModalLoading ? <Loading /> : referModalError ? <StyledParagraph>There was an error loading the invite functionality.</StyledParagraph> : <>
                    <StyledParagraph>You have <strong>{referInvitesRemaining}</strong> invites left. Type a student email below and click "Invite."</StyledParagraph>
                    <StyledFormGroup>
                        <StyledFormControl
                            type="email"
                            value={referModalEmail}
                            onChange={(e) => setReferModalEmail(e.target.value)}
                        />
                    </StyledFormGroup></>}</CustomModal>
            {/*<CustomModal show={showReferModal} onClose={() => setShowReferModal(false)} onRefer={referRequest}/>*/}
            <StyledModal show={whyModalShow} onHide={handleWhyClose} backdrop="static"
                keyboard={false} centered>
                <StyledModal.Header closeButton>
                    <StyledModal.Title>{whyModalStep == 1 ? "Why Meet" : "Why Pool"}?</StyledModal.Title>
                </StyledModal.Header>
                <StyledModal.Body>
                    {whyModalStep == 1 && 
                    <>


<StyledParagraph>In today’s fast-paced world, traditional networking methods like LinkedIn are becoming increasingly ineffective. Reaching out to people, waiting for responses, and managing connections manually is time-consuming and often leaves those with busy schedules behind. Prospinity changes the game by allowing you to network on autopilot.</StyledParagraph>

<StyledParagraph><strong>Effortless Connections:</strong> Our AI matches you with peers based on your background and goals, eliminating the need to search and reach out manually.</StyledParagraph>

<StyledParagraph><strong>Time-Efficient:</strong> Save time and energy as our AI handles the networking process for you, ensuring you meet the right people without the hassle.</StyledParagraph>

<StyledParagraph><strong>Personalized Matches:</strong> Our algorithm considers your interests, goals, and experiences to find the most relevant connections, making your networking efforts more targeted and effective.</StyledParagraph>

<StyledParagraph>Join Prospinity and revolutionize the way you network. With our AI, you can build meaningful connections effortlessly and focus on what truly matters.</StyledParagraph>
</>
                    
                    }
                    {whyModalStep == 2 && <>
                        <StyledParagraph><strong>Upside Access:</strong> Upside successes are often driven by unpredictable factors. Bet on the future upside potential of your peers. If one person wins, everyone wins. </StyledParagraph>

                        <StyledParagraph><strong>Diversification:</strong> Take part socially and financially in careers and life paths you didn't get to experience. In the next years, industries will rise and fall, companies will go out of business, and new technologies will develop. We don't put all of our eggs in one basket with our investments; why do it with our career? </StyledParagraph>

                        <StyledParagraph><strong>Unparalleled Network:</strong> Pool members share career networks, resources, opportunities, and insights. Gain access to a unique network of equally capable peers that is truly invested in you.</StyledParagraph></>}

                </StyledModal.Body>
                <StyledModal.Footer>
                    <div>
                        <StyledButton variant="secondary" onClick={handleWhyLeft} className='mx-3'>
                            {whyModalStep == 1 && <>Skip</>}
                            {whyModalStep == 2 && <>Back</>}
                        </StyledButton>
                        <StyledButton variant="secondary" onClick={handleWhyRight} className='mx-3'>
                            {whyModalStep == 1 && <>Next</>}
                            {whyModalStep == 2 && <>Close</>}
                        </StyledButton>
                    </div>
                </StyledModal.Footer>
            </StyledModal>
            <EditAvailabilityModal updateAvailabilityModalShow={promptAvailabilityModalShow} setUpdateAvailabilityModalShow={setPromptAvailabilityModalShow} onSave={() => {}}/>
        </Container>
        </Track>


    );
}