import Loading from "./Loading"; 
import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { myAxiosInstance } from "./axiosConfig";
import CustomModal from "./CustomModal";
import Availability from "./Availability";
import { StyledFormGroup, StyledFormLabel, StyledFormCheck } from "./StyledBootstrap";
 
 const EditMeetingGoalsModal = ({onSave, updateGoalModalShow, setUpdateGoalModalShow}) => {

    const [editMeetingGoal, setEditMeetingGoal] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [updateMessage, setUpdateMessage] = useState(<>How can Prospinity help you network?</>);
    const [updateLoad, setIsUpdateLoad] = useState(false);

    const { showBoundary } = useErrorBoundary();


    const handleGoalRadioChange = (value) => {
        setEditMeetingGoal(value);
    };

    const goals = [ "Meet peers in similar careers.", "Learn about different career paths.", "Share and receive feedback on ideas.", "Start a company." ];



    const updateGoal = async () => {
        setIsUpdateLoad(true)
        setUpdateMessage(<Loading />);
        try {
        const response = await myAxiosInstance.put('/api/user-availability/', {goal : editMeetingGoal});
        }
        catch (error) {
            console.error('Error updating user availability', error.response.data);
            showBoundary(error);
        }
        finally
        {
            setIsUpdateLoad(false);
            setUpdateGoalModalShow(false);
            onSave();
        }
        
        console.log("Update");
    };

    const handleUpdateModalClose = () => {
        if (!updateLoad)
        {
            setUpdateGoalModalShow(false);
        }
        
    }


    useEffect(
        () => {
            const getGoal = async () => {
            try {
                setUpdateMessage(<>How can Prospinity help you network?</>);

                const response = await myAxiosInstance.get('/api/user-availability/');
                console.log("This is the edit availability response", response);
                console.log(response.data.availability);
                let av = response.data.goal;
                setEditMeetingGoal(av);

            }
            catch (error) {
                console.log("im in the error");
                console.error(error);
                showBoundary(error);
            }
            finally {
                setIsBottomLoading(false);
            }

            }


            getGoal();
        }, [updateGoalModalShow]

    );

    return (
        <CustomModal title={'Update Meeting Goal'} actionText={'Save'} actionFunction={updateGoal} show={updateGoalModalShow} message={updateMessage} handleClose={handleUpdateModalClose}>
                {isBottomLoading ? <Loading /> : 

                <StyledFormGroup className="mt-3">
                <div>



                {goals.map((goal) => (
                    <div>
                    <StyledFormCheck
                    inline
                    label={goal}
                    name="prospinityGoalPreference"
                    type="radio"
                    
                    onChange={() => handleGoalRadioChange(goal)}
                    checked={editMeetingGoal === goal}
                />
                </div>
                ))}
                    
                
                </div>
            </StyledFormGroup>
                }
        </CustomModal>

    );



 }


 export default EditMeetingGoalsModal;