import { useState } from "react";
import { StyledButton, StyledParagraph } from "./StyledBootstrap";
import { useTheme } from "styled-components";
import Loading from "./Loading";

const Availability = ({weeklyAvailability, setWeeklyAvailability, edit, isBottomLoading}) => {

    const [dayOfWeek, setDayOfWeek] = useState('Monday');
    const themeMode = useTheme();
    const daysOfWeek = { 'Monday': 'Mo', 'Tuesday': 'Tu', 'Wednesday': 'We', 'Thursday': 'Th', 'Friday': 'Fr', 'Saturday': 'Sa', 'Sunday': 'Su' };
    const [timeOptions, setTimeOptions] = useState({ 'Monday': [11, 20], 'Tuesday': [], 'Wednesday': [14, 22], 'Thursday': [], 'Friday': [11, 16], 'Saturday': [1], 'Sunday': [12, 16, 19] });

    const convertToTime = (time) => {

        if (time > 12) {
            return ((time - 12) + ':00 P.M.');
        }
        else {
            return (time + ':00 A.M.');
        }




    };

    const areAllTimesUnavailable = (day) => {
        return !timeOptions[day].some(time => weeklyAvailability[day].includes(time));
    };


    const handleAvailabilityUpdate = (time) => {
        if (edit)
        {

        
        if (weeklyAvailability[dayOfWeek].includes(time)) {
            setWeeklyAvailability({ ...weeklyAvailability, [dayOfWeek]: weeklyAvailability[dayOfWeek].filter(selected => selected != time) });
        }
        else {
            setWeeklyAvailability({ ...weeklyAvailability, [dayOfWeek]: [...weeklyAvailability[dayOfWeek], time] });
        }
    }


    }

    return (
        <div>
            {isBottomLoading ? <StyledParagraph><Loading /></StyledParagraph> :
<>
            <div className="d-flex mb-2 flex-wrap">{Object.keys(daysOfWeek).map((day) => (<StyledButton className='mx-2' key={day} style={{
                color: themeMode.theme === 'light' && areAllTimesUnavailable(day) ? themeMode.onSurface : themeMode.onPrimary, onSurfaceborderRadius: '100%', borderWidth: dayOfWeek === day ? '4px' : '1px', backgroundColor: areAllTimesUnavailable(day) ? 'transparent' : themeMode.primary, width: '2.5em',
                height: '2.5em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }} onClick={() => setDayOfWeek(day)}>{daysOfWeek[day]}</StyledButton>))}</div>
            <div className="d-flex flex-wrap">
                {timeOptions[dayOfWeek].length == 0 ? <StyledParagraph className="ml-2">No Available Times</StyledParagraph> :
                timeOptions[dayOfWeek].map((time) => (<StyledButton className='mx-1' style={{ color: themeMode.theme === 'light' && !weeklyAvailability[dayOfWeek].includes(time) ? themeMode.onSurface : themeMode.onPrimary, backgroundColor: weeklyAvailability[dayOfWeek].includes(time) ? themeMode.primary : 'transparent' }} onClick={() => handleAvailabilityUpdate(time)} key={time}>{convertToTime(time)}</StyledButton>))}
            </div></>}



        </div>

    );

};

export default Availability;