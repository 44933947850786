import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
function Loading() {

    return (
        
        <Row className='justify-content-center text-center'>
            <div >
                <Spinner animation="grow"></Spinner>
            </div>
        </Row>
        
    );
}

export default Loading;