import {StyledH2} from './StyledBootstrap'

function IncomeVerification() {

    return (
        <div className="text-center">
             <StyledH2>No Verifications Due</StyledH2>
        </div>
    );
}

export default IncomeVerification;