import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import TutorialModal from './TutorialModal';
import Notifications from './Notifications';
import NotificationsSmall from './NotficiationsSmall';

function NavbarSmallScreen({setShowReferModal, setWhyModalShow}) {

    const [expanded, setExpanded] = useState(false);
    const themeMode = useTheme();
    const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate } = useAuth();
    const navigate = useNavigate();

    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');
        
    }


    const getBaseFontSize = () => {
        // Get the font-size of the body element
        const fontSize = window.getComputedStyle(document.body).fontSize;
        // Parse the font size and return it as a number
        return parseFloat(fontSize);
    };


    const toggleNavbar = () => {
        setExpanded(!expanded);
        console.log('Expanded:', !expanded); // Log the new state
    };

    return (
        <StyledNavbar expanded={expanded} expand="lg" sticky='top'>
            <Navbar.Brand href="/dashboard/home" className="d-flex align-items-center ml-2">
            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="30rem" className="d-inline-block align-top" alt="Logo" />
            </Navbar.Brand>
            {/* <div className="d-flex align-items-center">
                <Dropdown show={showNotifications} onToggle={setShowNotifications}>
                    <Dropdown.Toggle as="span"  style={{ cursor: 'pointer', color: themeMode.onSurface }}> */}
                    {/* <Nav.Link onClick={()=>setShowReferModal(true)} style={{ color: themeMode.onSurface }}>Invite</Nav.Link> */}

                        {/* <FontAwesomeIcon icon={faBell} className="mx-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end"> */}
            {/* <NotificationsSmall className="d-flex align-items-end mx-2"  /> */}
                    {/* </Dropdown.Menu> /*}
                </Dropdown>
            </div> */}

            {/* <Container fluid>  */}
            {/* <Row className="align-items-center w-100">  */}
            <Col className="d-flex justify-content-end"> 
            <NotificationsSmall className='d-flex justify-content-end'  />
            </Col>

            {/* <Col md={3} className="d-flex justify-content-end">  */}

            <Navbar.Toggle onClick={toggleNavbar} aria-controls="responsive-navbar-nav" style={{border: '0px'}}/>


            <Navbar.Collapse id="responsive-navbar-nav">
                <SearchUsers />
                <Nav.Link as={Link} to="home" style={{ color: themeMode.onSurface }}>Home</Nav.Link>
                <Nav.Link as={Link} to="meet" style={{ color: themeMode.onSurface }}>Meet</Nav.Link>
                <Nav.Link as={Link} to="create-pool" style={{ color: themeMode.onSurface }}>Create Pool</Nav.Link>
                <Nav.Link as={Link} to="payments" style={{ color: themeMode.onSurface }}>Payments</Nav.Link>
                {/*<Nav.Link as={Link} to="faqs" style={{ color: themeMode.onSurface }}>FAQs</Nav.Link>*/}
                <Nav.Link onClick={()=>setShowReferModal(true)} style={{ color: themeMode.onSurface }}>Invite</Nav.Link>
                {/* <Nav.Link onClick={()=>setWhyModalShow(true)} style={{ color: themeMode.onSurface }}>Why?</Nav.Link> */}
                {/* <NotificationsSmall /> */}

                <StyledDropdown>
                    <StyledDropdown.Toggle>
                        My Account
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu align={'start'}>
                        <StyledDropdown.Item as={Link} to={"user/" + userSlug}><FontAwesomeIcon className="mr-2" icon={faUser} />Profile</StyledDropdown.Item>
                        <StyledDropdown.Item as={Link} to="settings"><FontAwesomeIcon className="mr-2" icon={faGear} />Settings</StyledDropdown.Item>
                        <StyledDropdown.Item as={Link} to="faqs"><FontAwesomeIcon className="mr-2" icon={faQuestion} />FAQs</StyledDropdown.Item>
                        {/*<StyledDropdown.Item href={"mailto:support@prospinity.com"}><FontAwesomeIcon className="mr-2" icon={faQuestion} />Support</StyledDropdown.Item>*/}
                        <StyledDropdown.Item onClick={logOutNavigate}><FontAwesomeIcon className="mr-2" icon={faSignOut} />Logout</StyledDropdown.Item>

                    </StyledDropdown.Menu>
                </StyledDropdown>
            </Navbar.Collapse>

            
            {/* </Col> */}


            {/* </Row> */}
            
            {/* </Container> */}



            
        </StyledNavbar>
    );
}

export default NavbarSmallScreen;