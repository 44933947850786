import { useState } from 'react';
import { Navbar, Nav, NavDropdown, Button, Row, Col, Container, InputGroup, Dropdown, Card } from 'react-bootstrap';
import logo from './Images/Logo1.png';
import axios from 'axios';
import { StyledCard, StyledFormControl, StyledForm, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useNavigate } from 'react-router-dom';
import EmailVerificationSent from './EmailVerificationSent';
import { useParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { errorString } from './CriticalFunctions';


function SignUp() {
    const themeMode = useTheme();
    const { key } = useParams();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [prospinityKey, setProspinityKey] = useState(key || '');
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();
    const {trackEvent, Track} = useTracking({event: "Sign Up"});


    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);


    const majors = [
        "African American Studies",
        "African Studies",
        "American Studies",
        "Anthropology",
        "Applied Mathematics",
        "Applied Physics",
        "Archaeological Studies",
        "Architecture",
        "Art",
        "Astronomy",
        "Astrophysics",
        "Biomedical Engineering",
        "Chemical Engineering",
        "Chemistry",
        "Classical Civilization",
        "Classics",
        "Cognitive Science",
        "Comparative Literature",
        "Computer Science",
        "Computer Science & Economics",
        "Computer Science & Mathematics",
        "Computer Science & Psychology",
        "Computing and Linguistics",
        "Computing and the Arts",
        "Earth and Planetary Sciences",
        "East Asian Languages & Literatures",
        "East Asian Studies",
        "Ecology & Evolutionary Biology",
        "Economics",
        "Economics & Mathematics",
        "Electrical Engineering",
        "Electrical Engineering & Computer Science",
        "English",
        "Environmental Engineering",
        "Environmental Studies",
        "Ethics, Politics & Economics",
        "Ethnicity, Race & Migration",
        "Film and Media Studies",
        "French",
        "German Studies",
        "Global Affairs",
        "Greek, Ancient and Modern",
        "History",
        "History of Art",
        "History of Science, Medicine, and Public Health",
        "Humanities",
        "Italian Studies",
        "Judaic Studies",
        "Latin American Studies",
        "Linguistics",
        "Mathematics",
        "Mathematics & Philosophy",
        "Mathematics & Physics",
        "Mechanical Engineering",
        "Modern Middle East Studies",
        "Molecular Biophysics & Biochemistry",
        "Molecular, Cellular, & Developmental Biology",
        "Music",
        "Near Eastern Languages & Civilizations",
        "Neuroscience",
        "Philosophy",
        "Physics",
        "Physics & Geosciences",
        "Physics & Philosophy",
        "Political Science",
        "Portuguese",
        "Psychology",
        "Religious Studies",
        "Russian",
        "Russian, East European, and Eurasian Studies",
        "Sociology",
        "South Asian Studies",
        "Spanish",
        "Special Divisional Major",
        "Statistics and Data Science",
        "Theater and Performance Studies",
        "Urban Studies",
        "Women’s, Gender, & Sexuality Studies"
    ];
    const residentialColleges = [
        "Berkeley College",
        "Branford College",
        "Davenport College",
        "Ezra Stiles College",
        "Jonathan Edwards College",
        "Benjamin Franklin College",
        "Grace Hopper College",
        "Morse College",
        "Pauli Murray College",
        "Pierson College",
        "Saybrook College",
        "Silliman College",
        "Timothy Dwight College",
        "Trumbull College"
    ];
    const handleEmailSubmit = (e) => {
        // Typically, you'd validate the email here or send it to an API before moving to the next step.
        e.preventDefault();
        authenticateInitialSignUp(email, password, verifyPassword, prospinityKey, setIsLoading, setMessage);
    };

    async function authenticateInitialSignUp(email, pw, vpw, proskey, setIsLoading, setMessage) {
        setIsLoading(true);
        setMessage(null);
        let response = null;
        
    
        try {
            const credentials = { 'email': email, 'password1': pw, 'password2': vpw, 'prospinity_key': proskey};
            console.log(credentials);
            response = await axios.post('/dj-rest-auth/registration/', credentials);
            setSent(true);
            //navigate("/verification-link-sent");
            setMessage("Please check your email for a verification link.")
            console.log(response.data);
            trackEvent({email: email, key: proskey});
        }
        catch (error) {
            console.log(Object.values(error.response.data)[0][0]);
            setMessage(Object.values(error.response.data)[0][0]);
            trackEvent({email: email, key: proskey, error: errorString(error) });
        }
        finally {
            setIsLoading(false);
    
        }
    }


    return (
        <Track>
    {sent ? <EmailVerificationSent><StyledParagraph className='mt-3'>Verification link sent. Please check your email.</StyledParagraph></EmailVerificationSent> : 
        <Container fluid style={{ height: '100vh' ,backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
            <Row className='justify-content-center'>
                <Col className='col-lg-4 col-md-6'>

                    <StyledCard>
                        <StyledCard.Body>
                        <Row className='justify-content-center mb-3'>
                                <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>
                                </Col>
                            </Row>

                            <StyledForm onSubmit={(e) => handleEmailSubmit(e)}>
                                <StyledFormGroup controlId="formBasicEmail">
                                    <StyledFormLabel>University Email Address</StyledFormLabel>
                                    <StyledFormControl
                                        type="email"
                                        placeholder="Enter your university email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </StyledFormGroup>
                                <StyledFormGroup controlId="formVerifyPassword">
                                    <StyledFormLabel>Prospinity Key</StyledFormLabel>
                                    <StyledFormControl
                                        type="password"
                                        placeholder="Enter Your Prospinity Key"
                                        value={prospinityKey}
                                        onChange={(e) => setProspinityKey(e.target.value)}
                                    />
                                </StyledFormGroup>
                                <StyledFormGroup controlId="formBasicPassword">
                                    <StyledFormLabel>Password</StyledFormLabel>
                                    <StyledFormControl
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </StyledFormGroup>
                                <StyledFormGroup controlId="formVerifyPassword">
                                    <StyledFormLabel>Verify Password</StyledFormLabel>
                                    <StyledFormControl
                                        type="password"
                                        placeholder="Verify Password"
                                        value={verifyPassword}
                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                    />
                                </StyledFormGroup>
                                
                                <StyledButton variant="primary" type="submit" className="mt-3 w-100">
                                    Sign Up
                                </StyledButton>
                            </StyledForm>
                            <div className='text-center'>
                                {isLoading && <StyledParagraph className='mt-3'>Signing in...</StyledParagraph>}
                                {message && <StyledParagraph className='mt-3'>{message}</StyledParagraph>}
                                <StyledParagraph className='mt-3'> Already Signed Up? <a href="/signin"> Sign In.</a></StyledParagraph>
                                <StyledParagraph className='mt-3'> By joining the platform, you consent to our <a href="/privacypolicy"> Privacy Policy</a> and <a href="/termsofuse"> Terms of Use</a>.</StyledParagraph>
                            </div>
                        </StyledCard.Body>
                    </StyledCard>
                </Col>
            </Row>

        </Container>}
        </Track>
    );

}




export default SignUp;




