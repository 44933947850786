import PoolPreviewCard from "./PoolPreviewCard";
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, Card, Container } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import Loading from "./Loading";
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5, StyledH4, StyledParagraph } from "./StyledBootstrap";
import { myAxiosInstance } from "./axiosConfig";
import Chat from "./Chat";
import { Link } from "react-router-dom";
import UserPreviewCard from "./UserPreviewCard";
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
//import './Home.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const Home = () => {
    console.log("my here is the token");
    console.log(localStorage.getItem('userToken'));


    const HorizontalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        speed: 7000,
        autoplaySpeed: 7000,
        cssEase: "linear",
        arrows: false
    };

    const VerticalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        slidesPerRow: 2,
        autoplay: true,
        autoplaySpeed: 7000,

        vertical: true,
        speed: 5000,
        infinite: true,
        cssEase: "linear",
        
    };

    const themeMode = useTheme();

    const [dataArray, setDataArray] = useState([]);
    const [suggestedPoolArray, setSuggestedPoolArray] = useState([]);
    const [suggestedUserArray, setSuggestedUserArray] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true); // make this true
    const [homeUserDetails, setHomeUserDetails] = useState(null);
    const { reload, setReload } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);




    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const getMyPoolsData = async () => {
            try {
                // console.log(await myAxiosInstance.get('/api/home/'));
                console.log("running use effect before....");
                const response = await myAxiosInstance.get('/api/home/');
                console.log("running use effect after....");
                console.log(response);
                console.log(response.data);
                setDataArray(response.data.user_pools || []);
                setHomeUserDetails(response.data.user_details);
                setSuggestedPoolArray(response.data.suggested_pools);
                setSuggestedUserArray(response.data.suggested_users);

                console.log("this is what it is being set to: ");
                console.log(response.data.suggested_users[0]);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
        getMyPoolsData();
    }, [reload, bottomReload]);

    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    return (
        isBottomLoading ? <Loading /> : <>
            <Row className='justify-content-center' style={{ backgroundColor: themeMode.background }}>
                <Col className='col-lg-6 col-md-8'>
                    <StyledCard>
                        <StyledCard.Body>
                            <Container fluid>
                                <Row className="mb-2">
                                    <div><StyledH5><strong>Welcome, {homeUserDetails.first_name}</strong></StyledH5></div>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                        <div><StyledH4><strong>{homeUserDetails.number_of_pools}</strong></StyledH4></div>
                                    </Col>
                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                        <div><StyledH4><strong>{homeUserDetails.majors_pooled_with}</strong></StyledH4></div>
                                    </Col>
                                    <Col className="d-flex flex-column justify-content-center align-items-center">
                                        <div><StyledH4><strong>{homeUserDetails.pools_captained}</strong></StyledH4></div>
                                    </Col>

                                </Row>
                                <Row >
                                    <Col className="d-flex flex-column justify-content-center">

                                        <div className='text-center' >Signed Pools</div>
                                    </Col>
                                    <Col className="d-flex flex-column justify-content-center">
                                        <div className='text-center' >Pooled Majors</div>  {/*AMOLI CHANGE */}
                                    </Col>
                                    <Col className="d-flex flex-column justify-content-center">
                                        <div className='text-center' >Pools Captained</div>
                                    </Col>
                                </Row>
                            </Container>
                        </StyledCard.Body>
                    </StyledCard>

                    {!isMdOrLarger && (
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <StyledH5 className="mb-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <div style={{ display: 'flex', overflowX: 'scroll', paddingTop: '10px', paddingBottom: '10px' }}>
                                    {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                        <div className="mx-2" key={index}>
                                            <UserPreviewCard preview_object={otherUserInfo} />
                                        </div>
                                    ))}
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                    )}

                    {/*!isMdOrLarger && (
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <StyledH5 className="mb-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <div className="loop-container">
                                    <div className="loop-wrapper">
                                        {suggestedUserArray.filter(user => user.first_name !== '').map((user, index) => (
                                            <div className="loop-item" key={index}>
                                                <UserPreviewCard preview_object={user} />
                                            </div>
                                        ))}
                                        {suggestedUserArray.filter(user => user.first_name !== '').map((user, index) => (
                                            <div className="loop-item" key={index + suggestedUserArray.length}>
                                                <UserPreviewCard preview_object={user} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                    )*/}


                    {/*!isMdOrLarger && (
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <StyledH5 className="mb-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <div className="slider-container">
                                    <Slider {...HorizontalSettings}>
                                        {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                            <div className="mx-2" key={index}>
                                                <UserPreviewCard preview_object={otherUserInfo} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                    )*/}




                    {/*!isMdOrLarger && (
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <StyledH5 className="mb-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <div style={{ display: 'flex', overflowX: 'scroll' }}>
                                    {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                        <div className="mx-2" key={index}>
                                            <UserPreviewCard preview_object={otherUserInfo} />
                                        </div>
                                    ))}
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                    )*/}

                    <StyledCard className="mt-3">
                        <StyledCard.Body>
                            <Container fluid>
                                <Row>
                                    <div><StyledH5><strong>My Pools</strong></StyledH5></div>
                                </Row>
                                <Row>
                                    {dataArray.length === 0 ? (
                                        <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                            You have not been invited to any pools. <Link to="/dashboard/create-pool">Create</Link> your own or wait for an invite.
                                        </StyledParagraph>
                                    ) : (
                                        dataArray.map((pool) => (
                                            <Col className='mt-3 col-md-6' key={pool.id}><PoolPreviewCard data={pool} /></Col>
                                        ))
                                    )}
                                </Row>
                            </Container>
                        </StyledCard.Body>
                    </StyledCard>

                    <StyledCard className="mt-3">
                        <StyledCard.Body>
                            <Container fluid>
                                <Row>
                                    <div><StyledH5><strong>My Meetings</strong></StyledH5></div>
                                </Row>
                                <Row>
                                    <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                        You have not been invited to any meetings yet. In the meanwhile, make sure to <Link to="/dashboard/meet">update your availability</Link> in the Meet Section.
                                    </StyledParagraph>
                                </Row>
                            </Container>
                        </StyledCard.Body>
                    </StyledCard>

                    <StyledCard className="mt-3">
                        <StyledCard.Body>
                            <Container fluid>
                                <Row>
                                    <div><StyledH5><strong>Featured Success Pools</strong></StyledH5></div>
                                </Row>
                                <Row>
                                    {suggestedPoolArray.map((pool) => (
                                        <Col className='mt-3 col-md-6' key={pool.id}><PoolPreviewCard data={pool} /></Col>
                                    ))}
                                </Row>
                            </Container>
                        </StyledCard.Body>
                    </StyledCard>

                    {/* <Chat /> */}
                </Col>



                {isMdOrLarger && (
                    <Col className="col-lg-3 col-md-4" style={{ position: 'relative' }}>
                        <StyledCard className="justify-content-center" style={{ height: '85vh', position: 'sticky', top: '100px'}}>
                            <StyledH5 className="mt-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                            <StyledCard.Body className='justify-content-center' style={{ overflowY: 'auto', padding: '5px'}}>
                                <div className="d-flex flex-wrap justify-content-center">
                                    {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                        <div className="mx-1 my-2" key={index}>
                                            <UserPreviewCard preview_object={otherUserInfo}/>
                                        </div>
                                    ))}
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                    </Col>
                )}

                {/*isMdOrLarger && (
                    <Col className="col-lg-3 col-md-4" style={{ position: 'relative' }}>
                        <StyledCard className="justify-content-center" style={{ height: '85vh', position: 'sticky', top: '100px' }}>
                            <StyledCard.Body>
                            <StyledH5 className="mt-1" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <div className="slider-container">
                                    <Slider {...VerticalSettings}>
                                    {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                        <div  className="my-2" key={index}>
                                            <UserPreviewCard preview_object={otherUserInfo} />
                                        </div>
                                    ))}
                                    </Slider>
                                </div>

                                {<div className="d-flex flex-wrap justify-content-center">
                                    {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                        <div className="mx-1 my-2" key={index}>
                                            <UserPreviewCard preview_object={otherUserInfo} />
                                        </div>
                                    ))}
                                </div>}
                            </StyledCard.Body>
                        </StyledCard>
                    </Col>
                )*/}
            </Row>
        </>
    );
}

export default Home;