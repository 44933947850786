//graceful error handling for unknown user
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Container, Card, Button, Image, Table, Form } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import Edit from './UserProfileEdit';
import { ProfilePicture } from './MiscComponents';
import { Pencil } from 'react-bootstrap-icons';
import { stringToColor, formatGraduationDateSummer } from './CriticalFunctions';
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5 } from './StyledBootstrap';
import { myAxiosInstance } from './axiosConfig';
import { BioModal } from './BioModal';
import './UserProfile.css';
import ProfilePictureUpload from './ProfilePictureUpload';
import Loading from './Loading';
import { Linkedin, Envelope, Telegram } from 'react-bootstrap-icons';
import { StyledButton } from './StyledBootstrap';

function UserProfile() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [fullUserData, setFullUserData] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);

    const { showBoundary } = useErrorBoundary();

    const [showBioModal, setShowBioModal] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalEditData, setModalEditData] = useState(null);

    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);

    const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

    const bubbleStyle = (color) => ({
        display: 'inline-block',
        padding: '5px 10px',
        margin: '5px',
        borderRadius: '15px',
        backgroundColor: color,
        color: 'black',
        fontSize: '0.8rem',
    });


    const modalOptions = [
        { modalTitle: 'Edit Name' },
        { modalTitle: 'Edit Graduation Date' },
        { modalTitle: 'Edit Program(s) of Study' },
        { modalTitle: 'Edit University' },
        { modalTitle: 'Edit School/Residential College' },
        { modalTitle: 'Edit Organizations' },
        { modalTitle: 'Edit Bio' },
        { modalTitle: 'Edit LinkedIn' },
    ];

    const [modalOptionsIndex, setModalOptionsIndex] = useState(null);


    const handlePencilClick = (index) => {
        setModalOptionsIndex(index);
    };

    const handleClose = () => {
        setModalShow(false);
        setModalMessage(null);
        setModalOptionsIndex(null);
        setModalEditData(fullUserData);
    }

    useEffect(() => {
        if (modalOptionsIndex != null) {
            setModalShow(true);
        }
    }, [modalOptionsIndex]);

    const handleMessageButtonClick = async () => {
        try {
            setIsIndividualMessageLoading(true);
            const response = await myAxiosInstance.post('/api/create-channel/', {recipient_slug: slug});
            setActiveChannel(response.data.channel_id);
            setChatVisible(true);

        }
        catch (error)
        {
            console.error('Error setting up channel', error.response.data);

        }
        finally{
            setIsIndividualMessageLoading(false);
        }
        


    };


    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setModalShow(false);
                setModalMessage(null);
                setModalOptionsIndex(null);
                const response = await myAxiosInstance.get('/api/public-profile/' + slug + '/');
                setFullUserData({ ...response.data, university: [response.data.university], company: [response.data.company] });
                setModalEditData({ ...response.data, university: [response.data.university], company: [response.data.company] });


            } catch (error) {
                console.error('Error fetching user details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getUserDetails();


    }, [slug, bottomReload]);


    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;

                const size = cardWidth * 0.3;
                setPictureSize(size);
            }
        };

        updateCardSize();
        console.log("initial update");

        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, [isBottomLoading]);


    return (
        <>
            {isBottomLoading ? (
                <Loading />
            ) : (

                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>
                        <StyledCard ref={cardRef}>
                        {userSlug === slug && (
                        <div className='w-100 d-flex justify-content-end py-2 px-2'>
                            <div className='icon-container'><Pencil onClick={() => handlePencilClick(0)} /></div>
                            
                        </div>
            )}
                            <StyledCard.Body>


                                <Container fluid="sm" className="d-flex flex-column justify-content-center">

                                    <Row className='justify-content-center mb-3'>
                                        <ProfilePicture name={(fullUserData.first_name + " " + fullUserData.last_name)} size={pictureSize} graduation_date={fullUserData.graduation_date} picture_link={fullUserData.profile_picture}></ProfilePicture>
                                    </Row>


                                    <Row className='justify-content-center mb-3'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div className='mx-2 icon-container'>
                                                <Linkedin
                                                    onClick={() => fullUserData.linkedin_url.length > 0
                                                        ? window.open(fullUserData.linkedin_url.startsWith('www.')
                                                            ? "https://" + fullUserData.linkedin_url
                                                            : fullUserData.linkedin_url)
                                                        : null}
                                                />
                                            </div>
                                            <div className='mx-2 icon-container'>
                                                <a href={`mailto:${fullUserData.email}`}>
                                                    <Envelope />
                                                </a>
                                            </div>
                                            
                                        </div>
                                    </Row>
                                    



                                    



                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left d-flex justify-content-between'>
                                            <StyledH5>{fullUserData.first_name + " " + fullUserData.last_name}{fullUserData.company[0] !== "" ? " - " + fullUserData.company[0] : ""} </StyledH5>
                                        </Col>
                                    </Row>
                                    


                                    <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-left d-flex justify-content-between'>


                                            <div>{fullUserData.university[0]}

                                            </div>
                                            
                                        </Col>
                                    </Row>


                                    <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-left d-flex justify-content-between'>


                                            
                                            <div>
                                                <i>
                                                {fullUserData.major_or_grad_program.map((major) => (
                                                    <>{major}, </>
                                                ))}
                                                {formatGraduationDateSummer(fullUserData.graduation_date)}</i>

                                            </div>
                                            
                                        </Col>
                                    </Row>

                                    {fullUserData.bio.length > 0 ?
                                        <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                            <Col className='text-left d-flex justify-content-between'>


                                                <div>{fullUserData.bio}

                                                </div>
                                                
                                                
                                            </Col>
                                        </Row> : <></>}

                                        {userSlug !== slug &&
                                    <Row className='justify-content-center mb-3'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <StyledButton onClick={handleMessageButtonClick}>{isIndividualMessageLoading ? <Loading /> : "Message"}</StyledButton>
                                            
                                        </div>
                                    </Row>}



                                    {/*userSlug !== slug && fullUserData.linkedin_url.length == 0 ? <></> :
                                        <Row className='align-items-center mb-3'>
                                            <Col className='text-center'>
                                                <div style={bubbleStyle(stringToColor(fullUserData.linkedin_url))} className="aboutNameBubble" onClick={() => fullUserData.linkedin_url.length > 0 ? window.open(fullUserData.linkedin_url.startsWith('www.') ? "https://" + fullUserData.linkedin_url : fullUserData.linkedin_url) : null}>{userSlug === slug && fullUserData.linkedin_url.length == 0 ? "Add LinkedIn" : fullUserData.linkedin_url}</div>
                                                {userSlug === slug ? <Pencil className='ml-3' onClick={() => handlePencilClick(7)}></Pencil> : <></>}
                                            </Col>
            </Row>*/}

                                    {/*<Row className='align-items-center mb-3'>
                                        <Col className='text-center'>
                                            <div style={bubbleStyle(stringToColor(fullUserData.first_name))} className="aboutNameBubble" onClick={() => fullUserData.bio.length > 0 || userSlug === slug ? setShowBioModal(true) : null}>{fullUserData.first_name}'s BIO</div>
                                            {userSlug === slug ? <Pencil className='ml-3' onClick={() => handlePencilClick(6)}></Pencil> : <></>}
                                        </Col>
        </Row>*/}


                                    {/*<Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-center'>
                                            <div style={bubbleStyle(stringToColor(formatGraduationDateSummer(fullUserData.graduation_date)))}>{formatGraduationDateSummer(fullUserData.graduation_date)}</div>
                                            {userSlug === slug ? <Pencil className='ml-3' onClick={() => handlePencilClick(1)}></Pencil> : <></>}
                                        </Col>
    </Row>*/}





                                    {/*<Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-center'>
                                            <div>
                                                {fullUserData.major_or_grad_program.map((major, index) => (
                                                    <div key={index} style={bubbleStyle(stringToColor(major))}>{major}</div>
                                                ))}
                                                {userSlug === slug ? <Pencil className='ml-3' onClick={() => handlePencilClick(2)}></Pencil> : <></>}
                                            </div>

                                        </Col>
                                            </Row>*/}


                                    





                                </Container>
                            </StyledCard.Body>
                        </StyledCard>
                        {modalOptionsIndex != null ? <Edit show={modalShow}
                            setShow={setModalShow}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            bottomReload={bottomReload}
                            setBottomReload={setBottomReload}
                            reload={reload}
                            setReload={setReload}
                            modalTitle={modalOptions[modalOptionsIndex].modalTitle}
                            data={fullUserData}
                            handleClose={handleClose}
                            modalEditData={modalEditData}
                            setModalEditData={setModalEditData} /> : <></>}
                        <BioModal show={showBioModal} onHide={() => setShowBioModal(false)} bio={fullUserData.bio} first_name={fullUserData.first_name} />
                    </Col>
                </Row>


            )}
        </>
    );
}

export default UserProfile;
/*

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Container, Card, Button, Image, Form } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import Edit from './UserProfileEdit';
import { ProfilePicture } from './MiscComponents';
import { Pencil } from 'react-bootstrap-icons';
import { formatGraduationDateSummer } from './CriticalFunctions';
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard } from './StyledBootstrap';
import { myAxiosInstance } from './axiosConfig';
import { BioModal } from './BioModal';
import './UserProfile.css';
import ProfilePictureUpload from './ProfilePictureUpload';
import Loading from './Loading';

function UserProfile() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [fullUserData, setFullUserData] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { userSlug, reload, setReload } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);
    const { showBoundary } = useErrorBoundary();
    const [showBioModal, setShowBioModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalEditData, setModalEditData] = useState(null);
    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);

    const modalOptions = [
        { modalTitle: 'Edit Name' },
        { modalTitle: 'Edit Graduation Date' },
        { modalTitle: 'Edit Program(s) of Study' },
        { modalTitle: 'Edit University' },
        { modalTitle: 'Edit School/Residential College' },
        { modalTitle: 'Edit Organizations' },
        { modalTitle: 'Edit Bio' },
        { modalTitle: 'Edit LinkedIn' },
    ];

    const [modalOptionsIndex, setModalOptionsIndex] = useState(null);

    const handlePencilClick = (index) => {
        setModalOptionsIndex(index);
    };

    const handleClose = () => {
        setModalShow(false);
        setModalMessage(null);
        setModalOptionsIndex(null);
        setModalEditData(fullUserData);
    }

    useEffect(() => {
        if (modalOptionsIndex != null) {
            setModalShow(true);
        }
    }, [modalOptionsIndex]);

    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setModalShow(false);
                setModalMessage(null);
                setModalOptionsIndex(null);
                const response = await myAxiosInstance.get('/api/public-profile/' + slug + '/');
                setFullUserData({ ...response.data, university: [response.data.university] });
                setModalEditData({ ...response.data, university: [response.data.university] });
            } catch (error) {
                console.error('Error fetching user details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getUserDetails();
    }, [slug, bottomReload]);

    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;
                const size = cardWidth * 0.3;
                setPictureSize(size);
            }
        };

        updateCardSize();

        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, [isBottomLoading]);

    return (
        <>
            {isBottomLoading ? (
                <Loading />
            ) : (
                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>
                        <StyledCard ref={cardRef}>
                            <StyledCard.Body>
                                <Container fluid="sm" className="d-flex flex-column justify-content-center">
                                    <Row className='justify-content-center mb-3'>
                                        <ProfilePicture
                                            name={`${fullUserData.first_name} ${fullUserData.last_name}`}
                                            size={pictureSize}
                                            graduation_date={fullUserData.graduation_date}
                                            picture_link={fullUserData.profile_picture}
                                        />
                                    </Row>

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <h2>{`${fullUserData.first_name} ${fullUserData.last_name}`}</h2>
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(0)} />}
                                        </Col>
                                    </Row>

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <div>{fullUserData.university[0]}</div>
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(3)} />}
                                        </Col>
                                    </Row>

                                    {userSlug !== slug && fullUserData.linkedin_url.length === 0 ? null : 
                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <div onClick={() => fullUserData.linkedin_url.length > 0 ? window.open(fullUserData.linkedin_url.startsWith('www.') ? "https://" + fullUserData.linkedin_url : fullUserData.linkedin_url) : null}>
                                                {userSlug === slug && fullUserData.linkedin_url.length === 0 ? "Add LinkedIn" : fullUserData.linkedin_url}
                                            </div>
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(7)} />}
                                        </Col>
                                    </Row>}

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <div onClick={() => fullUserData.bio.length > 0 || userSlug === slug ? setShowBioModal(true) : null}>
                                                {fullUserData.first_name}'s BIO
                                            </div>
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(6)} />}
                                        </Col>
                                    </Row>

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <div>{formatGraduationDateSummer(fullUserData.graduation_date)}</div>
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(1)} />}
                                        </Col>
                                    </Row>

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            {fullUserData.major_or_grad_program.map((major, index) => (
                                                <div key={index}>{major}</div>
                                            ))}
                                            {userSlug === slug && <Pencil className='ml-3' onClick={() => handlePencilClick(2)} />}
                                        </Col>
                                    </Row>

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left'>
                                            <a href={`mailto:${fullUserData.email}`}>
                                                {fullUserData.email}
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard>
                        {modalOptionsIndex != null && (
                            <Edit
                                show={modalShow}
                                setShow={setModalShow}
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                bottomReload={bottomReload}
                                setBottomReload={setBottomReload}
                                reload={reload}
                                setReload={setReload}
                                modalTitle={modalOptions[modalOptionsIndex].modalTitle}
                                data={fullUserData}
                                handleClose={handleClose}
                                modalEditData={modalEditData}
                                setModalEditData={setModalEditData}
                            />
                        )}
                        <BioModal show={showBioModal} onHide={() => setShowBioModal(false)} bio={fullUserData.bio} first_name={fullUserData.first_name} />
                        <ProfilePictureUpload />
                    </Col>
                </Row>
            )}
        </>
    );
}

export default UserProfile;*/
