import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
//import colors from './colors.js';
import { Modal, Button, Navbar, Nav, Card, ListGroup, Dropdown, Form, FormControl, FormCheck, FormLabel, InputGroup, FormGroup, FormSelect, Accordion} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
// Example for light theme

// Define a border-radius value
const borderRadius = '1rem'; // You can adjust this value as needed


// Styled Button
export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.onPrimary};
  border-radius: ${borderRadius}; // Apply rounded corners
  border-color: ${({ theme }) => theme.onSurface};

  &:hover {
    background-color: ${({ theme }) => theme.primaryVariant};
  }

  &:focus, &:active {
    outline: none;
    background-color: ${({ theme }) => theme.primary}; // Ensures background color remains consistent
    box-shadow: none;
    border-color: ${({ theme }) => theme.onSurface};
  }
`;

// Styled Navbar





export const StyledNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.surface};

  .navbar-toggler {
    border-color: ${({ theme }) => theme.onSurface};
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'><path stroke='${({ theme }) => theme.onSurface.replace('#', '%23')}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }


`;


export const StyledRangeInput = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
  }

  /* Chrome, Safari, Opera, and Edge Chromium styles */
  &::-webkit-slider-runnable-track {
    background-color: ${({ theme }) => theme.background};
    border-radius: 1.5rem;
    height: 0.5rem;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -4px;  /* Centers thumb on the track */
    background-color: ${({ theme }) => theme.onSurface};
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
  }

  &:focus::-webkit-slider-thumb {
    outline: 3px solid ${({ theme }) => theme.onSurface};
    outline-offset: 0.125rem;
  }

  /* Firefox styles */
  &::-moz-range-track {
    background-color: ${({ theme }) => theme.background};
    border-radius: 1.5rem;
    height: 0.5rem;
  }

  &::-moz-range-thumb {
    background-color: ${({ theme }) => theme.onSurface};
    border: none; /* Removes extra border that FF applies */
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
  }

  &:focus::-moz-range-thumb {
    outline: 3px solid ${({ theme }) => theme.onSurface};
    outline-offset: 0.125rem;
  }
`;




// Styled Card
export const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.surface};
  color: ${({ theme }) => theme.onSurface};
  border-radius: ${borderRadius};
  overflow: hidden; // Ensures the border-radius applies to children elements



  // Styled Card Header
  .card-header {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.onPrimary};
  }

  // Styled Card Body
  .card-body {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
  }


  // Styled Card Footer
  .card-footer {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.onSecondary};
  }

  // Styled Card Title
  .card-title {
    color: ${({ theme }) => theme.onSurface};
  }

  // Styled Card Text
  .card-text {
    color: ${({ theme }) => theme.onSurface};
  }
`;

export const StyledBorderCard = styled(StyledCard)`
  border: 1px solid ${({ theme }) => theme.onSurface};
  borderRadius: ${borderRadius};
`;


export const StyledListGroup = styled(ListGroup)`
  // Styles for the ListGroup container
  background-color: ${({ theme }) => theme.surface};
  color: ${({ theme }) => theme.onSurface};
  border-color: ${({ theme }) => theme.surface};

  .list-group-item {
    // Styles for each ListGroup item
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-color: ${({ theme }) => theme.surface};

  }
`;



export const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-radius: ${borderRadius};
    border: none;  // Remove border

    &:hover, &:focus {
        background-color: ${({ theme }) => theme.surface};
        color: ${({ theme }) => theme.onSurface};
        border-radius: ${borderRadius};
        border: none;  // Remove border
        outline: none; // Remove the blue outline
    }
  }

  .dropdown-menu {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-radius: ${borderRadius};
    border: 1px solid ${({ theme }) => theme.onSurface}; // Adding a border
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); // Optional: Adding a shadow for depth

    .dropdown-item {
      color: ${({ theme }) => theme.onSurface};

      &:hover, &:focus {
        background-color: ${({ theme }) => theme.secondaryVariant};
        color: ${({ theme }) => theme.onSecondary};
      }

      // Override for the active state to maintain the hover color
      &:active {
        background-color: ${({ theme }) => theme.secondaryVariant} !important;
        color: ${({ theme }) => theme.onSecondary} !important;
        // Ensure this matches your :hover and :focus styles
      }
    }
  }
`;



export const StyledForm = styled(Form)`
  // Form styles
  border-radius: ${borderRadius};

`;

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 1rem;
  border-radius: ${borderRadius};
`;

// Styled FormControl
export const StyledFormControl = styled(FormControl)`
  background-color: ${({ theme }) => theme.form};
  color: ${({ theme }) => theme.onBackground};
  // border: 1px solid ${({ theme }) => theme.primary};
  border-radius: ${borderRadius};

  &:focus {
    background-color: ${({ theme }) => theme.form};
    color: ${({ theme }) => theme.onBackground};
    border: 1px solid ${({ theme }) => theme.onSurface};
    box-shadow: none; // Removes the default focus glow
  }
`;


export const StyledTextArea = styled(FormControl)`
  background-color: ${({ theme }) => theme.form};
  color: ${({ theme }) => theme.onBackground};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: ${borderRadius};
  width: 100%; // Ensure it occupies the full width
  height: auto; 
  resize: vertical; // Allows vertical resize only
  padding: 1em;

  &:focus {
    background-color: ${({ theme }) => theme.form};
    color: ${({ theme }) => theme.onBackground};
    border: 1px solid ${({ theme }) => theme.onSurface};
    box-shadow: none; // Removes the default focus glow
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputInput { // Assuming the input has a class name you can target
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.onSurface};
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: ${borderRadius};

    &:focus {
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.onSurface};
      border: 1px solid ${({ theme }) => theme.onSurface};
      box-shadow: none;
    }
  }

  // Add additional selectors for other nested elements as needed
`;

// Styled FormCheck
export const StyledFormCheck = styled(FormCheck)`
  .form-check-input {
    /* Default state styles */
    background-color: ${({ theme }) => theme.form};
    border-color: ${({ theme }) => theme.onSurface};

    /* When the input is checked */
    &:checked {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.primary};
      /* Attempt to style the toggle circle */
      &::after {
        background-color: ${({ theme }) => theme.primary}; /* Adjust 'secondary' to the theme color you want for the circle */
      }
    }
  }

  .form-check-label {
    color: ${({ theme }) => theme.onSurface};
  }

  /* Additional styles to target the inner circle more specifically if needed */
  &:active::before {
    background-color: ${({ theme }) => theme.onSurface} !important;
  }
`;


// Styled FormLabel
export const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.onSurface};
`;

export const StyledExplanatoryText = styled.p`
  font-size: 0.8rem; // smaller font size
  color: ${({ theme }) => theme.onBackground}; // using theme color for consistency
  margin-top: 0px; // remove any unwanted space above the text
  margin-bottom: 0.5em; // space below the text for separation
`;

// Styled InputGroup
export const StyledInputGroup = styled(InputGroup)`
  input, .input-group-text {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-radius: ${borderRadius};
  }
`;


export const StyledFormSelect = styled(FormSelect)`
  background-color: ${({ theme }) => theme.form};
  color: ${({ theme }) => theme.onBackground};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: ${borderRadius};

  &:focus {
    background-color: ${({ theme }) => theme.form};
    color: ${({ theme }) => theme.onBackground};
    border-color: ${({ theme }) => theme.onSurface};
    box-shadow: none;
  }

  &:invalid {
    color: grey;
  }
`;


export const StyledAccordion = styled(Accordion)`
  .accordion-item {
    background-color: ${({ theme }) => theme.surface};
    border: 1px solid ${({ theme }) => theme.onSurface};
    border-radius: ${borderRadius}; // AMOLI CHANGE
    border-bottom: none; // Remove bottom border
  

    &:last-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.onSurface}; // Only add bottom border to last item
      
    }

    .accordion-header {
      .accordion-button {
        position: relative;
        background-color: ${({ theme }) => theme.surface};
        color: ${({ theme }) => theme.onSurface};
        border-radius: ${borderRadius};  // AMOLI CHANGE
        background-image: none;
        text-align: left;
        padding-right: 2.5rem;
        &::after {
          content: none;
        }
      }
    }

    .accordion-body {
      background-color: ${({ theme }) => theme.surface};
      color: ${({ theme }) => theme.onSurface};
      border-radius: ${borderRadius}; // AMOLI CHANGE

    }
  }
`;








export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px; // Adjust as needed
  }

  .modal-content {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-radius: ${borderRadius};
  }

  .modal-header {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-bottom: 1px solid ${({ theme }) => theme.onSurface};
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }

  .modal-title {
    flex-grow: 1; // Makes sure title uses available space
  }

  .modal-body {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
  }

  .modal-footer {
    background-color: ${({ theme }) => theme.surface};
    color: ${({ theme }) => theme.onSurface};
    border-top: 1px solid ${({ theme }) => theme.onSurface};
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }

  .close {
    color: ${({ theme }) => theme.onSurface};
    &:hover {
      color: ${({ theme }) => theme.onSurface};
    }
  }
`;




export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.onSurface};
  font-size: 2.5em; // Adjust size as needed
`;

// Styled Heading 2
export const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.onSurface};
  font-size: 2em;
`;

// Styled Heading 3
export const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.onSurface};
  font-size: 1.75em;
`;

// Styled Heading 4
export const StyledH4 = styled.h4`
  color: ${({ theme }) => theme.onSurface};
  font-size: 1.5em;
`;

// Styled Heading 5
export const StyledH5 = styled.h5`
  color: ${({ theme }) => theme.onSurface};
  border-radius: ${borderRadius};
  font-size: 1.25em;
`;

// Styled Heading 6
export const StyledH6 = styled.h6`
  color: ${({ theme }) => theme.onSurface};
  font-size: 1em;
`;



export const StyledNavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.onSurface} !important; // Normal link color
  &.active {
    color: ${({ theme }) => theme.primary} !important; // Active link color
    font-weight: bold; // Optional: makes the active link bold
    // Add more styles for active link here
  }
`;



export const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.onSurface}; // Use the onSurface color for text
`;
