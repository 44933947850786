import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledModal } from './StyledBootstrap';

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.onSurface};
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.onSurface};
  }
`;

const StyledModalClose = ({ children, ...props }) => {
  const themeMode = useTheme();

  return (
    <StyledModal {...props}>
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <CloseButton onClick={props.onHide}>
          <FontAwesomeIcon icon={faTimes} fontSize={'1.5em'} color={themeMode.onSurface}/>
        </CloseButton>
      </div>
      {children}
    </StyledModal>
  );
};

export default StyledModalClose;
