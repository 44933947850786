//fix button centering

//add the slug to captain
//make click hoverable
//change so that user_status not status

import { Card, ListGroup, Row, Accordion, Button, Col, Container } from "react-bootstrap";
import PoolDeadlineTimer from "./PoolDeadlineTimer";
import { formatToDollar } from "./CriticalFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { StyledBorderCard, StyledButton, StyledAccordion } from "./StyledBootstrap";
import { useAuth } from "./AuthContext";
import './Pool.css';
import { pastDeadline, stylePoolSlug, convertPackageToGreek } from "./CriticalFunctions";
import { useTheme } from "styled-components";
import {useTracking} from 'react-tracking';

const PoolPreviewCard = ({ data }) => {

  const { accountStage } = useAuth();
  const themeMode = useTheme();

  const {Track, trackEvent} = useTracking({event: "Pool View"});

  const buttonOptions = [
    { text: 'Accept Invite' },
    { text: 'Invite Accepted' },
    { text: 'View and Sign' },
    { text: 'Manage Pool' },
    { text: 'View Signed Contract' },
    { text: 'Request' },
    { text: 'Verify Account' },
    { text: 'Evaluate' }
  ];
  const navigate = useNavigate();



  let buttonOptionsIndex = 5; // Updated logic using the local variable
  if (data.user_status === "invited_not_accepted") {
    buttonOptionsIndex = 0;
  } else if (data.user_status === "invited_accepted") {
    if (data.is_captain) {
      buttonOptionsIndex = 3;
    } else {
      buttonOptionsIndex = 1;
    }
  } else if (data.user_status === "accepted_not_signed") {
    if (accountStage == 3) {
      if (data.evaluate)
      {
        buttonOptionsIndex = 7;
      }
      else
      {
        buttonOptionsIndex = 2;
      }
      
    }
    else {
      buttonOptionsIndex = 5;
    }
  } else if (data.user_status === "accepted_signed") {
    buttonOptionsIndex = 4;
  }

  const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
  {
    return null;
  };

  const handleHeaderClick = () => {
    trackEvent({pool: data.slug});
    navigate('/dashboard/pool/' + data.slug);
  };


  const handleButtonClick = () => //change this
  {
    trackEvent({pool: data.slug});
    navigate('/dashboard/pool/' + data.slug, { state: { actionButtonClicked: true } });
  }


  const handleCaptainClick = () => {
    if (data.hasOwnProperty('captain_slug')) {
      navigate('/dashboard/user/' + data.captain_slug);
    }
  }

  return (

<Track>
    <StyledBorderCard>
<StyledBorderCard.Header className="d-flex justify-content-between align-items-center text-center text-ellipsis" onClick={handleHeaderClick}>
  {/* Left placeholder for alignment, takes up space only when icon is not visible */}
  <div className="d-flex d-md-none flex-grow-1"></div>
  
  {/* Conditional rendering of the icon for md screens or above, takes up space and pushes title to center */}
  <div className="d-none d-md-flex justify-content-start align-items-center  flex-grow-1">
    <strong style={{ color: themeMode.secondary }}>
      {data.pool_package !== 'Custom' ? convertPackageToGreek(data.pool_package) : ''}

      
    </strong>
  </div>

  {/* Title always centered */}
  <strong className="mx-auto captain-name">
    {/*data.user_status !== "not_associated" ? data.name : stylePoolSlug(data.slug.toUpperCase())*/}
    {data.name}
  </strong>
  
  {/* Right placeholder to ensure the title remains centered, matches left placeholder's behavior */}
  <div className="flex-grow-1"></div>
</StyledBorderCard.Header>

      <StyledBorderCard.Body>
        <StyledBorderCard.Text className='captain-name text-ellipsis' onClick={handleCaptainClick}>
          <strong>Captain:</strong> {data.hasOwnProperty('captain_slug') ? data.captain_first_name + " " + data.captain_last_name : "Private"} {data.is_captain && <FontAwesomeIcon icon={faStar} />}
        </StyledBorderCard.Text>
        
        {<StyledBorderCard.Text className='captain-name text-ellipsis' onClick={handleHeaderClick}>
          {data.evaluate ? <><strong>Status: </strong> Evaluation Period</> : <>
          {pastDeadline(data.sign_deadline) ? <strong>Status: </strong> : pastDeadline(data.invitation_deadline) ? <strong>Sign Deadline: </strong> : <strong>Invitation Deadline: </strong>}
          <PoolDeadlineTimer invitationDeadline={data.invitation_deadline} signDeadline={data.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>
  </>}</StyledBorderCard.Text>}
        



        <StyledBorderCard.Text className='captain-name text-ellipsis' onClick={handleCaptainClick}>
          <strong>Type:</strong> {data.pool_package}
        </StyledBorderCard.Text>
        <StyledAccordion>
          <StyledAccordion.Item eventKey="0">
            <StyledAccordion.Header>Terms  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
            <StyledAccordion.Body>
              <div text-ellipsis><strong>Threshold:</strong> {formatToDollar(data.threshold)}</div>
              <div text-ellipsis><strong>Contribution Percentage:</strong> {data.contribution_percent}%</div>
              <div text-ellipsis><strong>Time Horizon:</strong> {data.time_horizon} years</div>
            </StyledAccordion.Body>
          </StyledAccordion.Item>
        </StyledAccordion>
        <div className="d-flex justify-content-center mt-3">
  <StyledButton className='text-ellipsis w-75' onClick={handleButtonClick}>
    {buttonOptions[buttonOptionsIndex].text}
  </StyledButton>
</div>


        



      </StyledBorderCard.Body>



    </StyledBorderCard>
    </Track>
  );
};


export default PoolPreviewCard;