import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Modal, Form, Button, Card, FormCheck } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Settings.css';
import Persona from 'persona';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { myAxiosInstance } from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import { apiP } from './CriticalFunctions';
import CustomModal from './CustomModal';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ChevronRight } from 'react-bootstrap-icons';
import Loading from './Loading';
import { useErrorBoundary } from "react-error-boundary";
import { StyledListGroup, StyledH2, StyledCard, StyledFormCheck, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledH5, StyledForm, StyledPhoneInput, StyledParagraph } from './StyledBootstrap';
import { useTheme } from './ThemeContext';
//CHANGE notificaitnos, personal informatino, verification






function Settings() {
    const [notificationsEnabled, setNotificationsEnabled] = useState(false);
    const { userSlug } = useAuth();

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalEditData, setModalEditData] = useState(null);
    const [modalChangePassword, setModalChangePassword] = useState({ "old_password": "", "new_password1": "", "new_password2": "" });
    const [fullSettings, setFullSettings] = useState(null);

    const [bottomReload, setBottomReload] = useState(1);

    const [isBottomLoading, setIsBottomLoading] = useState(true);

    const {showBoundary} = useErrorBoundary();

    const navigate = useNavigate();



    const modalOptions = [
        'Edit Personal Information',
        'Edit Email Notifications',
        'Change Password',
        'Delete Account',
        'Change Theme'
    ];

    const [modalOptionsIndex, setModalOptionsIndex] = useState(null);


    const handlePencilClick = (index) => {
        setModalOptionsIndex(index);
    };

    const handleClose = () => {
        setModalShow(false);
        setModalMessage(null);
        setModalOptionsIndex(null);
        setModalEditData(fullSettings);
        setModalChangePassword({ "new_password1": "", "new_password2": "" });
        setNotificationsEnabled(fullSettings.email_verification);
    }

    useEffect(() => {
        if (modalOptionsIndex != null) {
            setModalShow(true);
        }
    }, [modalOptionsIndex]);

  
    const ThemeToggleSwitch = () => {
        const { theme, toggleTheme } = useTheme(); // Destructure theme and toggleTheme
    
        return (
            <StyledForm>
                <StyledFormCheck 
                    type="switch"
                    id="custom-switch"
                    label="Dark Mode"
                    checked={theme === 'dark'}
                    onChange={toggleTheme}
                    className='ml-3'
                />
            </StyledForm>
        );
    };
    


    useEffect(() => {
        const getUserSettings = async () => {
        
            try {
                setModalShow(false);
                setModalMessage(null);
                setModalOptionsIndex(null);
                setModalChangePassword({ "new_password1": "", "new_password2": "" });
                const response = await myAxiosInstance.get('/api/user-settings/');
                setFullSettings(response.data);
                setModalEditData(response.data);
                setNotificationsEnabled(response.data.email_verification);



            } catch (error) {
                console.error('Error fetching settings details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        
        };

        getUserSettings();

    }, [bottomReload]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModalEditData({ ...modalEditData, [name]: value });
    };


    const handleChangePasswordChange = (e) => {
        const { name, value } = e.target;
        setModalChangePassword({ ...modalChangePassword, [name]: value });
    };

    const handlePhoneChange = (value) => {
        // Custom handler for phone number input

        setModalEditData(prevData => ({
            ...prevData,
            phone: value ? value : "",
        }));
    };

    const handleNotificationToggle = () => {
        setNotificationsEnabled(!notificationsEnabled);
        setModalEditData({...modalEditData, email_verification: !notificationsEnabled});
    };

    const handleSaveChanges = async (e) => {
        if (modalOptions[modalOptionsIndex] === "Change Password") {
            const response = await apiP(modalChangePassword, "change-password/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "post");
        }
        else if (modalOptions[modalOptionsIndex] === "Delete Account" || modalOptions[modalOptionsIndex] === "Change Theme")
        {
            handleClose();
        }
        else {
            console.log("hanldling save changes");
            let data = { ...modalEditData}; //Change
            console.log(data);
            const response = await apiP(data, "user-settings/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "put");
            console.log(response);
        }

    };





    

    return (
        <>
            {isBottomLoading ? (
                <Loading/>
            ) : (
                <Row className='justify-content-center'>
                    <Col className='col-md-6'>
                        <StyledCard>
                            <StyledCard.Body>

                                <div className='text-center w-100'>
                                    <StyledH2>Settings</StyledH2>
                                </div>

                                <StyledListGroup variant="flush">
                                    

                                    <StyledListGroup.Item action onClick={() => handlePencilClick(0)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <StyledH5>Edit Personal Information</StyledH5>
                                            <ChevronRight />
                                        </div>
                                    </StyledListGroup.Item>
                                    <StyledListGroup.Item action onClick={() => handlePencilClick(1)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <StyledH5>Notification Settings</StyledH5>
                                            <ChevronRight />
                                        </div>
                                    </StyledListGroup.Item>
                                    <StyledListGroup.Item action onClick={() => handlePencilClick(2)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <StyledH5>Change Password</StyledH5>
                                            <ChevronRight />
                                        </div>
                                    </StyledListGroup.Item>
                                    <StyledListGroup.Item action onClick={() => handlePencilClick(4)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <StyledH5>Change Theme</StyledH5>
                                            <ChevronRight />
                                        </div>
                                    </StyledListGroup.Item>
                                    <StyledListGroup.Item action onClick={() => handlePencilClick(3)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <StyledH5>Delete Account</StyledH5>
                                            <ChevronRight />
                                        </div>
                                    </StyledListGroup.Item>
                                </StyledListGroup>


                                <CustomModal title={modalOptions[modalOptionsIndex]} actionText={"Save Changes"} actionFunction={handleSaveChanges} show={modalShow} setShow={setModalShow} message={modalMessage} setMessage={setModalMessage} handleClose={handleClose}>
                                    <StyledForm>

                                        {(modalOptions[modalOptionsIndex] === 'Edit Personal Information') &&
                                            <>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Phone Number</StyledFormLabel>
                                                    <StyledPhoneInput
                                                        placeholder="Enter phone number"
                                                        value={modalEditData.phone}
                                                        onChange={handlePhoneChange}
                                                        defaultCountry="US"
                                                        name="phoneNumber"
                                                        className="custom-phone-input"
                                                    />
                                                </StyledFormGroup>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Personal Email</StyledFormLabel>
                                                    <StyledFormControl
                                                        type="email"
                                                        value={modalEditData.personal_email}
                                                        onChange={handleInputChange}
                                                        name="personal_email"
                                                    >

                                                    </StyledFormControl>
                                                </StyledFormGroup>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Date of Birth</StyledFormLabel>
                                                    <StyledFormControl
                                                        type="date"
                                                        value={modalEditData.date_of_birth}
                                                        onChange={handleInputChange}
                                                        name="date_of_birth"
                                                    >

                                                    </StyledFormControl>
                                                </StyledFormGroup>
                                            </>
                                        }

                                        {modalOptions[modalOptionsIndex] == 'Change Password' &&
                                            <>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Type in Your Old Password</StyledFormLabel>
                                                    <StyledFormControl
                                                        type="password"
                                                        value={modalChangePassword.old_password}
                                                        onChange={handleChangePasswordChange}
                                                        name="old_password"
                                                    >

                                                    </StyledFormControl>
                                                </StyledFormGroup>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Type in Your New Password</StyledFormLabel>
                                                    <StyledFormControl
                                                        type="password"
                                                        value={modalChangePassword.new_password1}
                                                        onChange={handleChangePasswordChange}
                                                        name="new_password1"
                                                    >

                                                    </StyledFormControl>
                                                </StyledFormGroup>
                                                <StyledFormGroup>
                                                    <StyledFormLabel>Again: </StyledFormLabel>
                                                    <StyledFormControl
                                                        type="password"
                                                        value={modalChangePassword.new_password2}
                                                        onChange={handleChangePasswordChange}
                                                        name="new_password2"
                                                    >

                                                    </StyledFormControl>
                                                </StyledFormGroup>
                                            </>
                                        }

                                        {modalOptions[modalOptionsIndex] === 'Edit Email Notifications' &&
                                            <div>
                                                <StyledFormCheck
                                                    type="switch"
                                                    id="notification-switch"
                                                    inline
                                                    label={notificationsEnabled ? "On" : "Off"}
                                                    checked={notificationsEnabled}
                                                    onChange={handleNotificationToggle}
                                                /></div>
                                        }

                                        {modalOptions[modalOptionsIndex] === 'Delete Account' && <StyledParagraph>Please email <a href={"mailto:support@prospinity.com"}>support@prospinity.com</a> to delete your account.</StyledParagraph>}

                                        {modalOptions[modalOptionsIndex] === 'Change Theme' &&
                                            <ThemeToggleSwitch/>
                                        }
                                    </StyledForm>
                                </CustomModal>


                            </StyledCard.Body>
                        </StyledCard>
                    </Col>
                </Row>
            )}


        </>
    );
}

export default Settings;
