import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { StyledButton, StyledCard, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledForm, StyledParagraph } from './StyledBootstrap';
import logo from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import axios from 'axios';
import EmailVerificationSent from './EmailVerificationSent';

function PasswordResetEmail() {
    const themeMode = useTheme();
    const { verificationKey } = useParams();
    const [message, setMessage] = useState("Enter your email to receive a password reset link.");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');


    async function sendPasswordVerificationEmail(setIsLoading, email, setMessage) {
        try {
            setIsLoading(true);
            const response = await axios.post('/dj-rest-auth/password/reset/', { "email": email});
            setMessage("Password reset link sent to your email.");
        } catch (error) {
            console.log(error);
            setMessage(Object.values(error.response.data)[0][0]);
        }
        finally{
            setIsLoading(false);
        }
    }


    return (
        message==="Password reset link sent to your email." ? 
        
        <EmailVerificationSent><StyledParagraph className='mt-3'>{message}</StyledParagraph></ EmailVerificationSent>
        :
        <div>
            <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>

                    <StyledCard>
                        <StyledCard.Body>
                            <Row className='justify-content-center mb-3'>
                                <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>
                                </Col>
                            </Row>
                        <StyledFormGroup controlId="formBasicEmail">
                            <StyledFormLabel>Enter Your Email</StyledFormLabel>
                            <StyledFormControl
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </StyledFormGroup>
                        <StyledButton variant="primary" onClick={() => sendPasswordVerificationEmail(setIsLoading, email, setMessage)} className="w-100">
                            Send Reset Link
                        </StyledButton>
                    <div className='text-center mt-3'>
                        {isLoading && <StyledParagraph>Sending Email...</StyledParagraph>}
                        {message && <StyledParagraph>{message}</StyledParagraph>}
                        <StyledParagraph>Go back to <Link to="/signin">Sign In.</Link></StyledParagraph>
                        
                    </div>
                    </StyledCard.Body>
                    </StyledCard>
                    </Col>
                </Row>
            </Container>

        </div>);
}

export default PasswordResetEmail;


