import { StyledModal, StyledButton, StyledParagraph } from './StyledBootstrap';

export const BioModal = ({ show, onHide, bio, first_name }) => {
  return (
    <StyledModal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title id="contained-modal-title-vcenter">
          {first_name}'s Bio
        </StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>
        <StyledParagraph>{bio}</StyledParagraph>
      </StyledModal.Body>
      <StyledModal.Footer>
        <StyledButton onClick={onHide}>Close</StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
};


