import Loading from "./Loading"; 
import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { myAxiosInstance } from "./axiosConfig";
import CustomModal from "./CustomModal";
import Availability from "./Availability";
 
 const EditAvailabilityModal = ({onSave, updateAvailabilityModalShow, setUpdateAvailabilityModalShow}) => {

    const [editWeeklyAvailability, setEditWeeklyAvailability] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [updateMessage, setUpdateMessage] = useState(<>Set your weekly default availability for 1:1 AI matched meetings with other Prospinity members. Times are in EST.</>);
    const [updateLoad, setIsUpdateLoad] = useState(false);

    const { showBoundary } = useErrorBoundary();


    const updateAvailability = async () => {
        setIsUpdateLoad(true);
        console.log(JSON.stringify(editWeeklyAvailability));
        setUpdateMessage(<Loading />);
        try {
        const response = await myAxiosInstance.put('/api/user-availability/', {"availability": JSON.stringify(editWeeklyAvailability)});
        }
        catch (error) {
            console.error('Error updating user availability', error.response.data);
            showBoundary(error);
        }
        finally
        {
            setIsUpdateLoad(false);
            setUpdateAvailabilityModalShow(false);
            onSave();
        }
        
        console.log("Update");
    };

    const handleUpdateModalClose = () => {
        if (!updateLoad)
        {
            setUpdateAvailabilityModalShow(false);
        }
        
    }


    useEffect(
        () => {
            const getAvailability = async () => {
            try {
                setUpdateMessage(<>Set your weekly default availability for 1:1 AI matched meetings with other Prospinity members.</>);

                const response = await myAxiosInstance.get('/api/user-availability/');
                console.log("This is the edit availability response", response);
                console.log(response.data.availability);
                let av = response.data.availability;

                if (typeof av === "string") {
                    av = JSON.parse(av);
                }
                

                if (Object.keys(av).length == 0)
                {
                    setEditWeeklyAvailability({ 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [], 'Sunday': [] });
                }
                else{
                    setEditWeeklyAvailability(av);
                }
            }
            catch (error) {
                console.log("im in the error");
                console.error(error);
                showBoundary(error);
            }
            finally {
                setIsBottomLoading(false);
            }

            }


            getAvailability();
        }, [updateAvailabilityModalShow]

    );

    return (
        <CustomModal title={'Update Availability'} actionText={'Save'} actionFunction={updateAvailability} show={updateAvailabilityModalShow} message={updateMessage} handleClose={handleUpdateModalClose}>
                {isBottomLoading ? <Loading /> : <Availability weeklyAvailability={editWeeklyAvailability} setWeeklyAvailability={setEditWeeklyAvailability} edit={true} isBottomLoading={isBottomLoading}/>}
        </CustomModal>

    );



 }


 export default EditAvailabilityModal;